import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';

import { useNavigate } from 'react-router'

const webUrl = process.env.REACT_APP_WEB_URL;
const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token")

const MasterSkills = () => {
    const PageSize = 10;

    const [SkillData, setSkillData] = useState([]);
    const [isLoaded, SetisLoaded] = useState(true)

    const [rid, setrid] = useState("");
    const [srno, setSrno] = useState("");
    const [skillName, setSkillName] = useState("");
    const [actionType, setActionType] = useState("Add");

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate()

    useEffect(() => {

        if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
            navigate('/')
        }
    }, [])

    useEffect(() => {

        FillData();

    }, [isLoaded])

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return SkillData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, SkillData]);


    const FillData = async () => {

        var result = await APIHandler.get(`admin/skills`)
        setSkillData(result.data)



    }
    const Addbtn = async () => {

        setActionType("Add");

        window.$("#modal_1").modal("show")

    }
    const EditData = async (id) => {
        setActionType("Update");
        setrid(id);

        window.$("#modal_1").modal("show")

        var result = await APIHandler.get(`admin/skills/${id}`)
        // setSessionData(result.data)

        setSkillName(result.data.skillName);

    }
    const DeleteData = async (id) => {
        setrid(id);

        window.confirm('Are you sure you want to delete?')
        {

            var config = {
                headers: {
                    'authorization': token,
                },
            };
            const result = await axios.delete(`${baseUrl}admin/skills/${id}`, config)
            if (result != null) {
                FillData();
            }
        }
    }

    const HidePopup = async () => {

        window.$("#modal_1").modal("hide")

    }
    //add Skill
    const AddData = async (reqdata) => {
        SetisLoaded(false)

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.post(`${baseUrl}admin/skills/`, JSON.stringify(reqdata), headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }
        SetisLoaded(true)
        alert("Question successfully added")

    }

    const UpdateeData = async (reqdata) => {
        SetisLoaded(false)

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.put(`${baseUrl}admin/skills/${rid}`, JSON.stringify(reqdata), headers).then((response) => {
                alert("Successfully updated")
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }

        SetisLoaded(true)

    }
    const CallOnAdd = async () => {



        if (skillName == undefined || skillName == "")
            alert("Please enter skill ")


        else {

            SetisLoaded(false)

            if (actionType === "Add") {
                var data = {

                    "skillName": skillName

                }

                AddData(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")

                        SetisLoaded(true)

                    }

                })
            }
            else {
                var data = {

                    "skillName": skillName
                }
                UpdateeData(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")

                        SetisLoaded(true)
                    }

                })

            }

        }
        FillData();

    }

    return (
        <>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Skills</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                                <li className="breadcrumb-item active">Skills</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="revenue_card_header">
                                    Manage Skills
                                </div>
                                <div className="card-body">
                                    <div className='fqaddbtn'>
                                        <button className="btn btn-primary" onClick={e => Addbtn()}>Add New </button>

                                    </div>
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr. </th>
                                                <th>Skills</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                (currentTableData !== null && currentTableData.length > 0) ?
                                                currentTableData.map((obj, index) => (
                                                    <tr>
                                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                                            <td>{obj.skillName}</td>
                                                            <td><span className="links" onClick={e => EditData(obj._id)}>Edit </span></td>
                                                            <td><span className="links" onClick={e => DeleteData(obj._id)}>Delete </span></td>

                                                        </tr>
                                                    )) : "No Data Found"}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={SkillData.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>

            {/* model Pay  */}
            <div class="modal fade"
                id="modal_1"
                tabIndex="-1"
                aria-hidden="true">
                <div class="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div class="modal-content">
                        {/* <!-- Modal header --> */}
                        <div class="modal-header">
                            <h4 class="modal-title text-center" id="modal_1">
                                <b> {actionType === "Add" ? "Add Skill" : "Edit Skill"}</b>
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={e => { window.$("#modal_1").modal("hide") }}
                                style={{ border: "none", background: "white" }}
                            >&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className='paymentdetails-row'>
                                <section className="content">

                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            Skill  :
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="text" className="form-control" placeholder="" value={skillName} onChange={(e) => setSkillName(e.target.value)} />
                                        </div>
                                    </div>



                                    <div className="row">

                                        <div className="col-lg-12 p-1">
                                            <button className="btn btn-primary" onClick={e => HidePopup(e)} > Cancel </button> &nbsp;
                                            <button className="btn btn-primary" value={actionType} onClick={e => CallOnAdd(e)} > {actionType} </button>

                                        </div>
                                    </div>

                                </section>

                            </div>
                        </div>




                    </div>

                </div>

                {/* <!-- Form END --> */}
            </div>
        </>
    )
}

export default MasterSkills
