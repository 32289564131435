import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
import { useNavigate } from 'react-router'
let PageSize = 10;
const webUrl = process.env.REACT_APP_WEB_URL;

const SessionBooked = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.PeriodDash : "";
    let statusfromdash = (location.state !== undefined && location.state !== null) ? location.state.StatusDash : "";
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        fetchParticipantData(id);
        setShow(true)
    };
    const [show, setShow] = useState(false);


    const [period, setPeriod] = useState("ThisYear");

    const [toDate, setToDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [searchBy, setSearchBy] = useState("");
    const [status, setStatus] = useState("All");

    const [currentPage, setCurrentPage] = useState(1);
    const [sessionId, setSessionId] = useState(0);

    const [bookedData, setBookedData] = useState([]);

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const navigate = useNavigate();
    useEffect(() => {

        if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
            navigate('/')
        }

        var p = period;
        if (periodfromdash !== null && periodfromdash !== undefined) {

            if (periodfromdash === 'ThisYear')
                p = "ThisYear"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"
        }
        if (statusfromdash !== null && statusfromdash !== undefined) {
            const finalStatus = (statusfromdash === '' || statusfromdash === 'All') ? "" : statusfromdash;
            setStatus(finalStatus);
        }

        setPeriod(p);
        searchData(p, searchBy)
    }, [])

    useEffect(() => {

        if (isSending)
            setIsSending(false);
            
        searchData(period, searchBy)

    }, [isSending]);



    const searchData = async (Period, SearchBy) => {

        console.log("Search Status", status)
        setCurrentPage(1);
        SetisLoaded(false)
        var result = await APIHandler.get(`admin/sessions?status=${status}&period=${Period}&startDate=${fromDate}&endDate=${toDate}&searchKey=${SearchBy}`)

        setBookedData(result.data);

        SetisLoaded(true)
        location.state = null
    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return bookedData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, bookedData]);


    const [ParticipantData, setParticipantData] = useState([]);
    // api call for fetching list of participants for a session using axios

    const fetchParticipantData = async (id) => {
        try {
            const response = await APIHandler.get(`admin/sessions/${id}`);
            if (response.success) {
                setParticipantData(response.data.participants);
            }

        }
        catch (error) {
            console.log(error);
        }
    }



    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Session Booked</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                            <li className="breadcrumb-item active">Session Booked</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">

                    <div className="col-lg-2">
                        <label>Status</label>
                        <select className="form-control" name="Period" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>

                    <div className="col-lg-2">
                        <label>Period</label>
                        <select className="form-control" name="Period" value={period} onChange={(e) => setPeriod(e.target.value)}>

                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                            <option value="Custom">Custom</option>
                        </select>
                    </div>
                    {period === "Custom" &&
                        <>
                            <div className="col-lg-2">
                                <label>From Date</label>
                                <input type="date" className="form-control" placeholder="From Date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-lg-2">
                                <label>To Date</label>
                                <input type="date" className="form-control" placeholder="To Date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>

                        </>
                    }


                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Programme Name" value={searchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >&nbsp;</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="revenue_card_header">
                                <h3>Session Booked</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Programme Name</th>
                                            <th>Mentor Name</th>
                                            <th>Session Date/Time </th>
                                            <th>Booked By</th>
                                            <th>Booked On</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isLoaded ? (
                                            (currentTableData !== null && currentTableData.length > 0) ?
                                                currentTableData.map((obj, index) => (
                                                    <tr key={obj._id}>
                                                        <td>{(currentPage - 1) * 10 + index + 1}</td>

                                                        <td>{obj.skill} </td>
                                                        <td><a href={webUrl + "/#/userprofile/" + obj.mentor._id} target="_blank">{obj.mentor ? obj.mentor.firstName + " " + obj.mentor.lastName : ""} </a></td>


                                                        <td>
                                                            {moment(obj.date).format("YYYY/MM/DD")} -
                                                            {moment(obj.date).format("hh:mm A")}
                                                        </td>
                                                        <td><a href={webUrl + "/#/userprofile/" + obj.mentees[0]._id} target="_blank">{obj.mentees.length > 0 ? obj.mentees[0].firstName + " " + obj.mentees[0].lastName : ""}
                                                        </a></td>
                                                        <td> {moment(obj.createdAt).format("YYYY/MM/DD")}</td>
                                                        <td>$ {obj.amount}</td>
                                                        <td>{obj.currentStatus}</td>
                                                        <td>
                                                            <div style={{ textAlign: "center" }}>
                                                                <button class="btn btn-info" onClick={e => handleShow(obj._id)}>View</button>
                                                            </div>
                                                        </td>



                                                    </tr>
                                                )) : <tr><td colSpan={7}>No data found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={bookedData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                                size="lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Participants Info</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Name</th>
                                                <th>User Name</th>
                                                <th>Device</th>
                                                <th>Location</th>
                                                <th>Join Time</th>
                                                <th>Leave Time</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isLoaded ? (
                                                (ParticipantData !== null && ParticipantData.length > 0) ?
                                                    ParticipantData.map((obj, index) => (
                                                        <tr>
                                                            <td>{(currentPage - 1) * 10 + index + 1}</td>

                                                            <td>{obj.name}</td>
                                                            <td>{obj.user_key}</td>


                                                            <td> {obj.device}</td>
                                                            <td>{obj.location}</td>
                                                            <td>  {moment(obj.join_time).format("YYYY/MM/DD")} -
                                                                {moment(obj.join_time).format("hh:mm A")}
                                                            </td>
                                                            <td> {moment(obj.leave_time).format("YYYY/MM/DD")} -
                                                                {moment(obj.leave_time).format("hh:mm A")}</td>



                                                        </tr>
                                                    )) : <tr><td colSpan={7}>No Joinee found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                        </tbody>
                                    </table>



                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>

                                </Modal.Footer>
                            </Modal>

                        </div>
                    </div>
                </div>

            </section>
        </div>





    </>);
}
export default SessionBooked;