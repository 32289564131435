import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
import { useNavigate } from 'react-router'

let PageSize = 10;
const webUrl = process.env.REACT_APP_WEB_URL;

const RejectedSession = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""


    const [Period, setPeriod] = useState("ThisYear");
  
    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");
    const [reason, setReason] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
   

    const [BookedData, setBookedData] = useState([]);
   
    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const navigate = useNavigate();
  useEffect(() => {

      if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
          navigate('/')
      }
  },[] )

    useEffect(() => {
       
        if (isSending) {
            setIsSending(false);

        }
        var p = Period;
        if (periodfromdash !== null && periodfromdash !== undefined) {
            console.log('satus value ', periodfromdash)
            if (periodfromdash === 'ThisYear')
                p = "ThisYear"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"


        }
        setPeriod(p);
        searchData(p,  SearchBy)
        console.log(Period);

    }, [isSending]);



    const searchData = async (Period, SearchBy) => {
       
        SetisLoaded(false)      
        var result = await APIHandler.get(`admin/sessions/rejected?period=${Period}&startDate=${FromDate}&endDate=${ToDate}&searchKey=${SearchBy}`)
    
        console.log("User ", result.data)
        setBookedData(result.data);
       
        SetisLoaded(true)
        location.state=null
    }
    const ViewReason = async (reason) => {
       
        window.$("#modalformentor").modal("show")
        setReason(reason)
       

    }
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return BookedData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, BookedData]);


    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Rejected Sessions</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                            <li className="breadcrumb-item active">Rejected Sessions</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">
                    
                    <div className="col-lg-2">
                        <label>Period</label>
                        <select className="form-control" name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>
                           
                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                           <option value="Custom">Custom</option> 
                        </select>
                    </div>
                    {Period === "Custom" &&
                        <>
                            <div className="col-lg-2">
                                <label>From Date</label>
                                <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-lg-2">
                                <label>To Date</label>
                                <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>

                        </>
                    }


                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Programme Name" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >&nbsp;</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="revenue_card_header">
                                <h3>Rejected Sessions</h3>                              
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Programme Name</th>
                                            <th>Mentor Name</th>
                                            <th>Session Date/Time </th>
                                            <th>Booked By</th>
                                            <th>Booked On</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                           
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {isLoaded ?(
                                        (currentTableData!==null && currentTableData.length>0)?
                                        currentTableData.map((obj,index) => (
                                            <tr>
                                            <td>{(currentPage - 1) * 10 + index+1}</td>
                                               
                                                <td>{obj.skill} </td>
                                                <td><a href={webUrl + "/#/userprofile/" + obj.mentor._id} target="_blank">{obj.mentor ? obj.mentor.firstName + " " + obj.mentor.lastName : ""} </a></td>
                                               
                                                        
                                               <td>
                                                   {moment(obj.date).format("YYYY/MM/DD")} - 
                                                    {moment(obj.date).format("hh:mm A")}
                                               </td> 
                                               <td><a href={webUrl + "/#/userprofile/" + obj.mentees[0]._id} target="_blank">{obj.mentees.length > 0 ? obj.mentees[0].firstName + " " + obj.mentees[0].lastName : ""}
                                                       </a></td>
                                                <td> {moment(obj.date).format("YYYY/MM/DD")}</td>                                             
                                                <td>$ {obj.amount}</td>
                                                <td><a onClick={e => ViewReason(obj.isRejected.reason)} className='link-css' >{obj.currentStatus}</a></td>
                                               
                                               


                                            </tr>
                                        )):<tr><td colSpan={7}>No data found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={BookedData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>

        <div class="modal fade"
            id="modalformentor"
            tabIndex="-1"
            aria-hidden="true">
            <div class="modal-dialog" style={{ maxWidth: "700px" }}>
                <div class="modal-content">
                    {/* <!-- Modal header --> */}
                    <div class="modal-header">
                        <h4 class="modal-title" id="modalforprofile">
                            <b>Reason</b>
                        </h4>
                        <button
                            type="button"
                            class="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={e => { window.$("#modalformentor").modal("hide") }}
                            style={{ border: "none", background: "white" }}
                        >&times;</button>
                    </div>
                    <div class="modal-body">
                        <div>
                        <>
                                            <div>
                                                <p>{reason}</p>
                                            </div>

                                        </>
                        </div>

                    </div>




                </div>
                <div class="modal-footer d-flex justify-content-between">

                </div>
            </div>

            {/* <!-- Form END --> */}
        </div>



    </>);
}
export default RejectedSession;