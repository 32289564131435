import React,{useState,useEffect} from "react";
import { Modal } from "react-bootstrap";
import APIHandler from "../../helper/APIHandler";
import moment from "moment";



const EnrollmentModal = ({ isOpen, onClose, sessionId }) => {
  const [enrollmentData, setEnrollmentData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isOpen) {
      getEnrollmentData();
    }
  }
  ,[isOpen]);

  const getEnrollmentData = async () => {
    try {
      setIsLoaded(true);
      const result = await APIHandler.get(`admin/group-sessions/sessions/${sessionId}/enrollments`);
      if (result.success) {
        const data = result?.data;
        console.log("data", result);  
        if (Array.isArray(data)) {
          setEnrollmentData(data);
          setErrorMessage("");
          setIsLoaded(false);
        } else {
          setEnrollmentData([]);
          setErrorMessage("No data found");
          setIsLoaded(false);
        }
      } else {
        setEnrollmentData([]);
        setErrorMessage("No data found");
        setIsLoaded(false);
      }
    } catch (error) {
      setEnrollmentData([]);
      setErrorMessage("No data found");
      setIsLoaded(false);
    }
  };


  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Enrollment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered ">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col"> Profile </th>
                  <th scope="col">Enrolled On </th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount Paid</th>
                  <th scope="col">Transaction Details</th>
                </tr>
              </thead>
              <tbody>
                {isLoaded ? (

                  <>
                  <div className="loader"></div>
                  </>
                ) : errorMessage ? (
                  <tr>
                    <td colSpan="6">{errorMessage}</td>
                  </tr>
                ) : (
                  enrollmentData && enrollmentData.map((enrollment) => (
                    <tr key={enrollment._id}>
                      <td>{enrollment?.firstName} {enrollment?.lastName}</td>
                      <td>
                        <a href={`/userprofile/${enrollment._id}`} target="_blank">
                          View Profile
                        </a>
                      </td>
                      <td>
                      {moment(enrollment?.createdAt).format("DD MMM YYYY")}
                      </td>
                      <td>{enrollment?.status}</td>
                      <td>${enrollment?.amountPaid}</td>
                      <td>{enrollment?.transactionId}</td>
                    </tr>
                  ))
                )}

             

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnrollmentModal;
