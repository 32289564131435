import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
import ReactSwitch from 'react-switch';
import { useNavigate } from 'react-router'
let PageSize = 10;
const webUrl = process.env.REACT_APP_WEB_URL;
const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token")

const PendingRequest = () => {


    const location = useLocation();

    let filterfromdash = (location.state !== undefined && location.state !== null) ? location.state.status : ""

    const [RequestData, setRequestData] = useState([]);
    const [Userid, setUserid] = useState("");
    const [filter, setFilter] = useState("Approved");
    const [SearchBy, setSearchBy] = useState("");


    const [currentPage, setCurrentPage] = useState(1);
    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)
    const [isAnswersLoaded, SetisAnswersLoaded] = useState(true)

    const [QuestionAnserData, setQuestionAnserData] = useState([]);
    const [toggle, setToggle] = useState(false);


    const [rid, setrid] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

        if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
            navigate('/')
        }
    }, [])

    useEffect(() => {

        if (isSending) {
            setIsSending(false);
        }
        if (filterfromdash !== "") {
            setFilter(filterfromdash)
        }

        searchData(filter, SearchBy)


    }, [isSending, filter]);



    const searchData = async (filter, SearchBy) => {

        SetisLoaded(false)
        var result = await APIHandler.get(`admin/users/pending-request?filter=${filter}&searchKey=${SearchBy}`)

        console.log("User ", result.data)
        setRequestData(result.data);

        SetisLoaded(true)
        location.state = null
    }


    //mentors accept
    const MentorAccept = async (id) => {
        if (window.confirm('Are you sure you want to approve ?')) {
            SetisLoaded(false)
            var result = await APIHandler.post(`admin/mentors/${id}/accept`)
            console.log(result, "Approve")
            if (result != undefined) {
                if (result.success) {

                    alert("User successfully approved")

                    setIsSending(true);
                    searchData(filter, SearchBy)

                }
            }
        }
        else {
            // Do nothing!
            console.log('Rakesh Cancel Reschedule');
        }

    }

    //mentors reject
    const MentorReject = async (id) => {
        if (window.confirm('Are you sure you want to reject ?')) {
            SetisLoaded(false)
            var result = await APIHandler.post(`admin/mentors/${id}/reject`)
            console.log(result, "Reject")
            if (result != undefined) {
                if (result.success) {
                    alert("User successfully rejected")


                    setIsSending(true);
                    searchData(filter, SearchBy)
                }
            }
        }
        else {

        }

    }

    // Block
    const ClickMentorBlock = async (id, upcomingSession) => {
      
        if (upcomingSession > 0) {
            alert("Cannot block mentor due to pending sessions")
          
        }
        else {
            setrid(id);

            var data = {

                "status": "Blocked"

            }


            MentorBlock(data, id).then(result => {
                if (result !== undefined) {



                    toast.success("Successfully Updated")


                    setIsSending(true);
                    searchData(filter, SearchBy)
                }

            })

        }

    }
    const MentorBlock = async (reqdata, id) => {

        SetisLoaded(false)
        window.confirm('Are you sure you want to block ?')
        {

            try {
                const headers = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': token
                    }
                }
                const response = await axios.patch(`${baseUrl}admin/users/${id}/mentor-status`, JSON.stringify(reqdata), headers).then((response) => {
                    return response
                }
                ).catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return alert(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return alert("You are not authorized to view this page")
                    }
                    else if (error.response.status === 404) {
                        return alert("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return alert("Internal server error please try after sometime")
                    }
                });
                return response.data
            } catch (error) {
                return alert('Something went wrong , Please check your internet connection.')
            }
            SetisLoaded(true)
        }


    }

    //View Anser
    const LoadQuestionAnswer = async (id) => {
        SetisAnswersLoaded(false)
        window.$("#modalformentor").modal("show")

        var result = await APIHandler.get(`admin/mentors/${id}/questions?`)

        setQuestionAnserData(result.data[0].mentorQuestions);
        SetisAnswersLoaded(true)
    }

    //update mentor status


    const UpdateMentorStatus = async (id, status) => {



        var data = {

            "status": !status
        }

        if (window.confirm('Are you sure you want to update status ?')) {


            try {
                const headers = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': token
                    }
                }

                const response = await axios.post(`${baseUrl}admin/mentors/${id}/topMentor`, JSON.stringify(data), headers).then((response) => {
                    alert("Successfully updated")
                    searchData(filter, SearchBy)
                    return response
                }
                ).catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return alert(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return alert("You are not authorized to view this page")
                    }
                    else if (error.response.status === 404) {
                        return alert("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return alert("Internal server error please try after sometime")
                    }
                });
                return response.data
            } catch (error) {
                return alert('Something went wrong , Please check your internet connection.')
            }

            SetisLoaded(true)

        }
    }



    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return RequestData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, RequestData]);


    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Mentors ({filter})</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                            <li className="breadcrumb-item active">Mentors </li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">

                    <div className="col-lg-2">
                        <label>Filter</label>
                        <select className="form-control" name="Filter" value={filter} onChange={(e) => setFilter(e.target.value)}>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Blocked">Blocked</option>
                        </select>
                    </div>

                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Name / Email" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >&nbsp;</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="revenue_card_header">
                                <h3>Total : {RequestData.length} </h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Joined On</th>
                                                <th>Location</th>
                                                <th>Job Title</th>
                                                <th>Requested On</th>


                                                <th>Action</th>
                                                <th>Answers</th>
                                                <th>Top Mentor</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isLoaded ? (
                                                (currentTableData !== null && currentTableData.length > 0) ?
                                                    currentTableData.map((obj, index) => (
                                                        <tr>
                                                            <td>{(currentPage - 1) * 10 + index + 1}</td>

                                                            <td><a href={webUrl + "/#/userprofile/" + obj._id} target="_blank">

                                                                {obj.firstName} {obj.lastName}
                                                            </a> </td>

                                                            <td>{obj.email} </td>
                                                            <td>
                                                                {moment(obj.createdAt).format("YYYY/MM/DD hh:mm A")}
                                                            </td>
                                                            <td>{obj.location}</td>
                                                            <td>{obj.currentJobTitle}</td>
                                                            <td>

                                                                {obj.reqeustedOn !== null && obj.reqeustedOn !== undefined ? moment(obj.reqeustedOn).format("YYYY/MM/DD hh:mm A") : ""}
                                                            </td>

                                                            <td>
                                                                {
                                                                    (obj.mentorApproval === "Pending") ?
                                                                        <>
                                                                            <div style={{ textAlign: "center", display: "flex", gap: "2px" }}>
                                                                                <button class="btn btn-success acction-btn" onClick={e => MentorAccept(obj._id)}>Approve</button>
                                                                                <button class="btn btn-danger acction-btn" onClick={e => MentorReject(obj._id)}>Reject</button>
                                                                            </div>
                                                                        </> :
                                                                        (obj.mentorApproval === "Rejected") ? <button class="btn btn-success acction-btn" onClick={e => MentorAccept(obj._id)}>Approve</button>
                                                                            : (obj.mentorApproval === "Blocked") ? <button class="btn btn-success acction-btn" onClick={e => MentorAccept(obj._id)}>Approve</button>
                                                                                : <button class="btn btn-danger" onClick={e => ClickMentorBlock(obj._id, obj.upcomingSessionCount)}>Block</button>


                                                                    // obj.mentorApproval

                                                                }

                                                            </td>
                                                            <td>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <button class="btn btn-info" onClick={e => LoadQuestionAnswer(obj._id)}>View</button>
                                                                </div>
                                                            </td>
                                                            <td>


                                                                <ReactSwitch
                                                                    checked={obj.isTopMentor}
                                                                    onChange={() => UpdateMentorStatus(obj._id, obj.isTopMentor)}
                                                                />
                                                            </td>

                                                        </tr>
                                                    )) : <tr><td colSpan={10}>No data found</td></tr>)
                                                : <tr><td colSpan={10}>  <div className="loader"></div></td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={RequestData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>


        <div class="modal fade"
            id="modalformentor"
            tabIndex="-1"
            aria-hidden="true">
            <div class="modal-dialog" style={{ maxWidth: "700px" }}>
                <div class="modal-content">
                    {/* <!-- Modal header --> */}
                    <div class="modal-header">
                        <h4 class="modal-title" id="modalforprofile">
                            <b>Answers</b>
                        </h4>
                        <button
                            type="button"
                            class="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={e => { window.$("#modalformentor").modal("hide") }}
                            style={{ border: "none", background: "white" }}
                        >&times;</button>
                    </div>
                    <div class="modal-body">
                        <div>
                            {isAnswersLoaded ? (
                                (QuestionAnserData !== null && QuestionAnserData.length > 0) ?
                                    QuestionAnserData.map((obj, index) => (
                                        <>
                                            <div>
                                                <p><b>Que {index + 1} : {obj.question}</b></p>
                                                <p><b>Ans : </b> {obj.answer} </p>
                                            </div>

                                        </>
                                    )) : "Record not found "
                            ) : <div className="loader"></div>}

                        </div>

                    </div>




                </div>
                <div class="modal-footer d-flex justify-content-between">

                </div>
            </div>

            {/* <!-- Form END --> */}
        </div>
        {/* <!-- Modal footer --> */}





    </>);
}
export default PendingRequest;