import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { useNavigate } from 'react-router'

const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token")


const CrawlingSetting =() => {

    
    const [LoadData, setLoadData] = useState([]);
    const [isLoaded, SetisLoaded] = useState(true)

    const [rid, setrid] = useState("");
    const [fullName, setFullName] = useState("");   
    const [profileUrl, setProfileUrl] = useState(""); 
    const [actionType, setActionType] = useState("Add");
    const navigate = useNavigate()

    useEffect(() => {

        if(localStorage.getItem("token") === null || localStorage.getItem("token") === undefined || localStorage.getItem("token") === ""){
            navigate('/')
        }
    },[] )

    useEffect(() => {

        FillData();

    }, [isLoaded])

    const FillData = async () => {

        var result = await APIHandler.get(`admin/crawling-settings`)
        setLoadData(result.data)
       
    

    }
    const Addbtn = async () => {
        
        setActionType("Add");
        
        window.$("#modal_1").modal("show")

    }
    const EditData = async (id) => {
        setActionType("Update");
        setrid(id);
        
        window.$("#modal_1").modal("show")

        var result = await APIHandler.get(`admin/crawling-settings/${id}`)
    
       
        setFullName(result.data.fullName);
        setProfileUrl(result.data.linkedInProfileUrl)
       
    }
    const DeleteData = async (id) => {
        setrid(id);
       
        window.confirm('Are you sure you want to delete?')
        {
           
            var config = {
                headers: {
                    'authorization': token,
                },
            };
            const result = await axios.delete(`${baseUrl}admin/crawling-settings/${id}`, config)
            if (result != null) {
                FillData();
            }
        }
    }

    const ClickStatus = async (id,status) => {
        setrid(id);
        var updatestatus=""
        if(status=="Crawled")
        {
            updatestatus="Pending"
        }
        else
        {
            updatestatus="Crawled"
        }

        var data = {
                   
            "status":updatestatus         
           
        }
        
        ChangeStatus(data,id).then(result => {
            if (result !== undefined) {

                window.$("#modal_1").modal("hide")

                SetisLoaded(true)
               
                toast.success("Successfully Updated")
            }

        })
       
    }
    const ChangeStatus = async (reqdata,id) => {
        SetisLoaded(false)
        window.confirm('Are you sure you want to change status?')
        {

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.patch(`${baseUrl}admin/crawling-settings/${id}`, JSON.stringify(reqdata), headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }
        SetisLoaded(true)
    }

    }


    const HidePopup = async () => {

        window.$("#modal_1").modal("hide")
       
    }
    //add Skill
    const AddData = async (reqdata) => {
        SetisLoaded(false)

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.post(`${baseUrl}admin/crawling-settings/`, JSON.stringify(reqdata), headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }
        SetisLoaded(true)
      

    }

    const UpdateData = async (reqdata) => {
        SetisLoaded(false)

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.put(`${baseUrl}admin/crawling-settings/${rid}`, JSON.stringify(reqdata), headers).then((response) => {
              return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }

        SetisLoaded(true)
       
    }
    const ClickOnAdd = async () => {



        if (fullName === undefined || fullName === "")
            alert("Please enter full name ") 
        if (profileUrl === undefined || profileUrl === "")
            alert("Please enter linkedin url ") 
        else {

            SetisLoaded(false)

            if (actionType === "Add") {
                var data = {
                   
                    "fullName": fullName,
                    "linkedInProfileUrl": profileUrl
                   
                }

                AddData(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")

                        SetisLoaded(true)
                        alert("Successfully Added")
                    }

                })
            }
            else {
                var data = {

                    "fullName": fullName,
                    "linkedInProfileUrl": profileUrl
                }
                UpdateData(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")

                        SetisLoaded(true)
                        alert("Successfully Updated")
                    }

                })

            }

        }
        FillData();

    }

    return (
        <>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Crawling Settings</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                                <li className="breadcrumb-item active">Crawling Settings</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="revenue_card_header">
                                    Manage Crawling Settings
                                </div>
                                <div className="card-body">
                                    <div className='fqaddbtn'>
                                        <button className="btn btn-primary" onClick={e => Addbtn()}>Add New </button>

                                    </div>
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr. </th>
                                                <th>Name</th>
                                                <th>Profile URL</th>
                                                <th>Updated On</th>
                                                <th>Status</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                (LoadData !== null && LoadData.length > 0) ?
                                                LoadData.map((obj, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{obj.fullName}</td>
                                                            <td>{obj.linkedInProfileUrl}</td>
                                                            <td>{obj.updatedAt}</td>
                                                            <td><span className="links" onClick={e=>ClickStatus(obj._id,obj.status)}>  {obj.status}</span></td>
                                                            <td><span className="links" onClick={e => EditData(obj._id)}>Edit </span></td>
                                                            <td><span className="links" onClick={e => DeleteData(obj._id)}>Delete </span></td>

                                                        </tr>
                                                    )) : "No Data Found"}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>

            {/* model Pay  */}
            <div class="modal fade"
                id="modal_1"
                tabIndex="-1"
                aria-hidden="true">
                <div class="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div class="modal-content">
                        {/* <!-- Modal header --> */}
                        <div class="modal-header">
                            <h4 class="modal-title text-center" id="modal_1">
                                <b> {actionType === "Add" ? "Add Crawling" : "Edit Crawling"}</b>
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={e => { window.$("#modal_1").modal("hide") }}
                                style={{ border: "none", background: "white" }}
                            >&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className='paymentdetails-row'>
                                <section className="content">
                                    
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                        Name  :
                                        </div>
                                        <div className="col-lg-12">
                                        <input type="text" className="form-control" placeholder="" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                         </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                        LinkedIn Profile URL  :
                                        </div>
                                        <div className="col-lg-12">
                                        <input type="text" className="form-control" placeholder="" value={profileUrl} onChange={(e) => setProfileUrl(e.target.value)} />
                                         </div>
                                    </div>


                                    <div className="row">

                                        <div className="col-lg-12 p-1">
                                            <button className="btn btn-primary" onClick={e => HidePopup(e)} > Cancel </button> &nbsp;
                                            <button className="btn btn-primary" value={actionType} onClick={e => ClickOnAdd(e)} > {actionType} </button>

                                        </div>
                                    </div>

                                </section>

                            </div>
                        </div>




                    </div>

                </div>

                {/* <!-- Form END --> */}
            </div>
        </>
    )
}

export default CrawlingSetting