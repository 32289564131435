import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import APIHandler from "../../helper/APIHandler";
import Pagination from "../layout/Pagination";
import { useNavigate } from "react-router";

const webUrl = process.env.REACT_APP_WEB_URL;
const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
let PageSize = 10;

const Payables = () => {
  const location = useLocation();
  let periodfromdash =
    location.state !== undefined && location.state !== null
      ? location.state.PeriodDash
      : "";

  const [Period, setPeriod] = useState("ThisYear");

  const [ToDate, setToDate] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [SearchBy, setSearchBy] = useState("");
  const [Status, setStatus] = useState("");

  const [sessionid, setSessionid] = useState("");
  const [totalSession, SetTotalSession] = useState(0);
  const [totalPayable, SetTotalPayable] = useState(0);
  const [totalPaid, SetTotalPaid] = useState(0);

  const [totalBalance, SetTotalBalance] = useState(0);

  const [sessionType, setsessionType] = useState("");
  const [session, setSession] = useState("");
  const [user, setUser] = useState("");

  const [mentor, setMentor] = useState("");
  const [mentorid, setMentorid] = useState("");
  const [sessionTimeUser, setsessionTimeUser] = useState("");
  const [sessionTimeMentor, setsessionTimeMentor] = useState("");

  const [mentorList, setMentorList] = useState([]);
  const [MenterData, setMenterData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [amountPaidData, setamountPaidData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [payto, setPayto] = useState("");
  const [paymentid, setPaymentid] = useState("");
  const [paytoName, setPaytoName] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payDate, SetPayDate] = useState("");
  const [payMOde, setPayMode] = useState("");
  const [transactionid, setTransactionsid] = useState("");
  const [remarks, setRemarks] = useState("");

  const [isSending, setIsSending] = useState(false);
  const [isLoaded, SetisLoaded] = useState(true);
  const [actionType, setActionType] = useState("Add");
  const [transactionFor, setTransactionFor] = useState("All");

  useEffect(() => {
    if (isSending) {
      setIsSending(false);
    }
    var p = Period;
    if (periodfromdash !== null && periodfromdash !== undefined) {
      console.log("satus value ", periodfromdash);
      if (periodfromdash === "ThisYear") p = "ThisYear";
      if (periodfromdash === "ThisMonth") p = "ThisMonth";
      if (periodfromdash === "ThisWeek") p = "ThisWeek";
      if (periodfromdash === "PreviousYear") p = "PreviousYear";
      if (periodfromdash === "PreviousMonth") p = "PreviousMonth";
      if (periodfromdash === "PreviousWeek") p = "PreviousWeek";
    }
    setPeriod(p);
    searchData(p, SearchBy);
    console.log(Period);
    FillMentor();
  }, [isSending]);

  const searchData = async (Period, SearchBy) => {
    SetisLoaded(false);
    var result = await APIHandler.get(
      `admin/payables?mentorId=${mentorid}&period=${Period}&startDate=${FromDate}&endDate=${ToDate}&searchKey=${SearchBy}&sessionType=${transactionFor}`
    );

    setMenterData(result.data.mentors);
    SetTotalSession(result.data.totalSessions);
    SetTotalPayable(result.data.payables);
    SetTotalPaid(result.data.paid);
    SetTotalBalance(result.data.balance);

    SetisLoaded(true);
    location.state = null;
  };
  const FillMentor = async () => {
    var result = await APIHandler.get(`admin/mentors/`);
    setMentorList(result.data);
    console.log("ddl list ", result.data);
  };
  //View Session Details
  const LoadSessionDetails = async (id) => {
    window.$("#modalSession").modal("show");
    setSessionid(id);
    var result = await APIHandler.get(
      `admin/payables/${id}/sessions?period=${Period}&startDate=${FromDate}&endDate=${ToDate}&searchKey=${SearchBy}`
    );
    setSessionData(result.data);
    console.log("Seession data", result.data);
  };
  //View Session Details
  const LoadPaidAmount = async (id, name) => {
    window.$("#modalPaidAmount").modal("show");

    setSessionid(id);

    setPaytoName(name);
    var result = await APIHandler.get(
      `admin/payables/${id}/payments?period=${Period}&startDate=${FromDate}&endDate=${ToDate}&searchKey=${SearchBy}`
    );
    setamountPaidData(result.data);
    console.log("Seession data", result.data);
  };
  //View Session Details

  //add payment
  const AddPaymentAPI = async (reqdata) => {
    try {
      const headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      const response = await axios
        .post(`${baseUrl}admin/payments/`, JSON.stringify(reqdata), headers)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.success === false)
              return alert(error.response.data.message);
          } else if (error.response.status === 401) {
            return alert("You are not authorized to view this page");
          } else if (error.response.status === 404) {
            return alert("Url not found");
          } else if (error.response.status === 500) {
            return alert("Internal server error please try after sometime");
          }
        });
      return response.data;
    } catch (error) {
      return alert(
        "Something went wrong , Please check your internet connection."
      );
    }
  };
  const UpdatePaymentAPI = async (reqdata) => {
    SetisLoaded(false);

    try {
      const headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      const response = await axios
        .put(
          `${baseUrl}admin/payments/${paymentid}`,
          JSON.stringify(reqdata),
          headers
        )
        .then((response) => {
          alert("Successfully updated");
          return response;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.success === false)
              return alert(error.response.data.message);
          } else if (error.response.status === 401) {
            return alert("You are not authorized to view this page");
          } else if (error.response.status === 404) {
            return alert("Url not found");
          } else if (error.response.status === 500) {
            return alert("Internal server error please try after sometime");
          }
        });
      return response.data;
    } catch (error) {
      return alert(
        "Something went wrong , Please check your internet connection."
      );
    }
  };
  const AddPayment = async () => {
    if (paytoName == undefined || paytoName == "")
      alert("Please enter pay to ");
    else if (payAmount == undefined || payAmount == "")
      alert("Please enter amount");
    else if (payMOde == undefined || payMOde == "")
      alert("Select payment mode");
    else if (transactionid == undefined || transactionid == "")
      alert("Please enter transaction id ");
    else {
      if (actionType === "Add") {
        SetisLoaded(false);
        var data = {
          user: payto,
          payoutDate: payDate,
          amount: payAmount,
          paymentMode: payMOde,
          transactionId: transactionid,
          remarks: remarks,
        };

        AddPaymentAPI(data).then((result) => {
          if (result !== undefined) {
            window.$("#modalPay").modal("hide");

            SetisLoaded(true);
          }
        });
      } else {
        SetisLoaded(false);
        var data = {
          user: payto,
          payoutDate: payDate,
          amount: payAmount,
          paymentMode: payMOde,
          transactionId: transactionid,
          remarks: remarks,
        };

        UpdatePaymentAPI(data).then((result) => {
          if (result !== undefined) {
            window.$("#modalPay").modal("hide");

            SetisLoaded(true);
          }
        });
      }
    }
    searchData(Period, SearchBy);
  };
  const payPopupShow = async (id, paytoname, amount) => {
    window.$("#modalPay").modal("show");
    setActionType("Add");
    setUser(id);
    setPayto(id);
    setPaytoName(paytoname);
    setPayAmount(amount);
  };
  const HidePopup = async () => {
    window.$("#modalPay").modal("hide");
  };
  const UpdatePayment = async (id) => {
    setActionType("Update");
    setPaymentid(id);
    window.$("#modalPay").modal("show");
    var result = await APIHandler.get(`admin/payments/${id}`);
    setPayto(sessionid);
    setPayAmount(result.data.amount).toFixed(2);
    SetPayDate(result.data.payoutDate);
    setPayMode(result.data.paymentMode);
    setTransactionsid(result.data.transactionId);
    setRemarks(result.data.remarks);
  };
  const DeletePayment = async (id) => {
    window.confirm("Are you sure you want to delete?");
    {
      var config = {
        headers: {
          authorization: token,
        },
      };
      const result = await axios.delete(
        `${baseUrl}admin/payments/${id}`,
        config
      );
      if (result != null) {
        window.$("#modalPaidAmount").modal("show");
      }
    }

    LoadPaidAmount(sessionid);
    window.$("#modalPaidAmount").modal("show");
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return MenterData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, MenterData]);

  return (
    <>
      <Layout />

      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Payables</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Payables</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div className="col-lg-2">
              <label>Period</label>
              <select
                className="form-control"
                name="Period"
                value={Period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <option value="ThisWeek">This Week</option>
                <option value="ThisMonth">This Month</option>
                <option value="ThisYear">This Year</option>
                <option value="PreviousWeek">Last Week</option>
                <option value="PreviousMonth">Last Month</option>
                <option value="PreviousYear">Last Year</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
            {Period === "Custom" && (
              <>
                <div className="col-lg-2">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From Date"
                    value={FromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-lg-2">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To Date"
                    value={ToDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="col-lg-4">
              <label>Mentor</label>
              <select
                className="form-control"
                name="Status"
                value={mentorid}
                onChange={(e) => setMentorid(e.target.value)}
              >
                <option value="">All</option>
                {mentorList.map((obj, index) => (
                  <option key={index} value={obj._id}>
                    {" "}
                    {obj.firstName + " " + obj.lastName} ({obj.email}){" "}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-lg-2">
              <label>Transaction For</label>
              <select
                className="form-control"
                value={transactionFor}
                onChange={(e) => setTransactionFor(e.target.value)}
              >
                <option value="All">All</option>
                <option value="OneToOne">One to One Session</option>
                <option value="GroupSession">Group Session</option>
              </select>
            </div>

            <div className="col-lg-2">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={SearchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <label>&nbsp;</label>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => setIsSending(true)}
                >
                  <i className="fa fa-search"></i> Search{" "}
                </button>
                {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <div>
                    <h3>Total Session Conducted : {totalSession}</h3>
                  </div>
                  <div className="card_header">
                    {" "}
                    <h3>Payables : $ {totalPayable.toFixed(2)} </h3>
                    <h3>Paid : $ {totalPaid.toFixed(2)} </h3>
                    <h3>Balance : $ {totalBalance.toFixed(2)} </h3>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Mentor Name </th>
                        <th>Sessions Conducted</th>
                        <th>Amount Payables</th>
  
                        <th>Amount Paid</th>
                        <th>Amount Bal.</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoaded ? (
                        currentTableData !== null &&
                        currentTableData.length > 0 ? (
                          currentTableData.map((obj, index) => (
                            <tr>
                              <td>{(currentPage - 1) * 10 + index + 1}</td>

                              <td>
                                {" "}
                                <a
                                  href={webUrl + "/#/userprofile/" + obj._id}
                                  target="_blank"
                                  className="links"
                                >
                                  {obj.firstName + " " + obj.lastName}
                                </a>
                              </td>

                              <td>
                                {" "}
                                <i
                                  className="links"
                                  onClick={(e) => LoadSessionDetails(obj._id)}
                                >
                                  {" "}
                                  {obj.sessionConducted}
                                </i>
                              </td>
                              <td>
                                <i
                                  className="links"
                                  onClick={(e) => LoadSessionDetails(obj._id)}
                                >
                                  $ {obj.payables.toFixed(2)}
                                </i>
                              </td>
                              <td>
                                <i
                                  className="links"
                                  onClick={(e) =>
                                    LoadPaidAmount(
                                      obj._id,
                                      obj.firstName + " " + obj.lastName
                                    )
                                  }
                                >
                                  $ {obj.paid.toFixed(2)}
                                </i>
                              </td>
                              <td>$ {obj.balance.toFixed(2)}</td>
                              <td>
                                <span
                                  className="links"
                                  onClick={(e) =>
                                    payPopupShow(
                                      obj._id,
                                      obj?.firstName + " " + obj?.lastName,
                                      obj?.balance
                                    )
                                  }
                                >
                                  Pay{" "}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            {" "}
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={MenterData.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* model sesssion  */}
      <div
        class="modal fade"
        id="modalSession"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ maxWidth: "1100px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="modalidsession">
                <b>Session List</b>
              </h4>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#modalSession").modal("hide");
                }}
                style={{ border: "none", background: "white" }}
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <section className="content">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>SessionID </th>
                        <th>Sessions </th>
                        {/* <th>user</th> */}
                        <th>Mentor</th>
                        <th>Date Time (User)</th>
                        <th>Date Time ( Menter)</th>
                        <th>Amount Payable</th>
                      </tr>
                    </thead>

                    <tbody>
                      {sessionData.map((obj, index) => (
                        <tr>
                          <td>{index + 1}</td>

                          <td>{obj._id}</td>
                          <td>{obj.sessionType}</td>

                          {/* <td>
                            {obj?.mentees?.firstName !== undefined ||
                            obj?.mentees?.firstName != null
                              ? obj?.mentees?.firstName
                              : ""}
                          </td> */}
                          <td>
                            {obj?.mentor?.firstName !== undefined ||
                            obj?.mentor?.firstName != null
                              ? obj?.mentor?.firstName
                              : ""}
                          </td>

                          <td>
                            {" "}
                            {moment(obj?.mentees?.date).format(
                              "YYYY/MM/DD hh:mm A"
                            )}
                          </td>
                          <td>
                            {" "}
                            {moment(obj.mentorDate).format(
                              "YYYY/MM/DD hh:mm A"
                            )}
                          </td>
                          <td>$ {obj.amount.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between"></div>
        </div>
        {/* <!-- Form END --> */}
      </div>

      {/* model Paid amount  */}
      <div
        class="modal fade"
        id="modalPaidAmount"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ maxWidth: "1100px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="modalPaidid">
                <b>Payment List</b>
              </h4>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#modalPaidAmount").modal("hide");
                }}
                style={{ border: "none", background: "white" }}
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <section className="content">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Payout Date Time </th>
                        <th>Amount</th>
                        <th>Mode</th>
                        <th>Transaction ID</th>
                        <th>Remarks</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {amountPaidData.map((obj, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {" "}
                            {moment(obj.payoutDate).format(
                              "YYYY/MM/DD hh:mm A"
                            )}
                          </td>
                            <td>$ {obj.amount.toFixed(2)}</td>
                          <td>{obj.paymentMode}</td>
                          <td>{obj.transactionId}</td>
                          <td> {obj.remarks}</td>
                          <td>
                            <span
                              className="links"
                              onClick={(e) => UpdatePayment(obj._id)}
                            >
                              Edit
                            </span>{" "}
                            <span
                              className="links"
                              onClick={(e) => DeletePayment(obj._id)}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between"></div>
        </div>

        {/* <!-- Form END --> */}
      </div>

      {/* model Pay  */}
      <div class="modal fade" id="modalPay" tabIndex="-1" aria-hidden="true">
        <div class="modal-dialog" style={{ maxWidth: "800px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="modalidpay">
                <b>Payment Record Entry</b>
              </h4>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#modalPay").modal("hide");
                }}
                style={{ border: "none", background: "white" }}
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="paymentdetails-row">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-4">Pay To :</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={paytoName}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Amount :</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={payAmount}
                        onChange={(e) => setPayAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Date :</div>
                    <div className="col-lg-8">
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        value={payDate}
                        onChange={(e) => SetPayDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Payment Mode :</div>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        name="Status"
                        value={payMOde}
                        onChange={(e) => setPayMode(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Venmo">Venmo</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Transaction ID:</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={transactionid}
                        onChange={(e) => setTransactionsid(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Remarks :</div>
                    <div className="col-lg-8">
                      <textarea
                        name="postContent"
                        rows={2}
                        className="form-control"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => HidePopup(e)}
                      >
                        {" "}
                        Cancel{" "}
                      </button>{" "}
                      &nbsp;
                      <button
                        className="btn btn-primary"
                        value={actionType}
                        onClick={(e) => AddPayment(e)}
                      >
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Form END --> */}
      </div>
    </>
  );
};
export default Payables;
