import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';



import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const token = localStorage.getItem("token")
const BASE_URL = process.env.REACT_APP_API_URL;

const AddCase = () => {




  const [cases, setCases] = useState([]);
  const [userList, setUserlist] = useState([]);
  const [Severity, setSeverity] = useState("")
  const [subject, setsubject] = useState("")
  const [caseType, setcaseType] = useState("")
  const [userid, setUserid] = useState("")
  const [description, setDescription] = useState("")
  const [attachment, setAttachment] = useState([])
  const navigate = useNavigate();
  useEffect(() => {

    if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
      navigate('/')
    }
  }, [])

  const [caseData, setCaseData] = useState([]);

  const [files, setFiles] = useState([]);


  const [user, setUser] = useState([])

  useEffect(() => {

    FillUser();


  }, []);

  const FillUser = async () => {

    var result = await APIHandler.get(`admin/users`)
    setUserlist(result.data)
    console.log("ddl list ", result.data)

  }

  const AddData = async (reqdata) => {


    try {
      const headers = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': token
        }
      }
      const response = await axios.post(`${BASE_URL}admin/allcase/add/`, reqdata, headers).then((response) => {
        return response
      }
      ).catch(error => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            return alert(error.response.data.message)
        }
        else if (error.response.status === 401) {
          return alert("You are not authorized to view this page")
        }
        else if (error.response.status === 404) {
          return alert("Url not found")
        }
        else if (error.response.status === 500) {
          return alert("Internal server error please try after sometime")
        }
      });
      return response.data
    } catch (error) {
      return alert('Something went wrong , Please check your internet connection.')
    }



  }


  const handleSubmit = (e) => {
    if (userid == undefined || userid == null || userid == "") {
      alert("Please Select User")
    }
    else if (Severity == undefined || Severity == null || Severity == "") {
      alert("Please Select Severity")
    }
    else if (caseType == undefined || caseType == null || caseType == "") {
      alert("Please Select Case Type")
    }
    else if (subject == undefined || subject == null || subject == "") {
      alert("Please Enter Subject ")
    }
    else if (description == undefined || description == null || description == "") {
      alert("Please Enter Description ")
    }

    else {

      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("severity", Severity);
      formData.append("caseType", caseType);
      formData.append("description", description);
      formData.append("userId", userid);
      formData.append("status", "Active");
      formData.append("created_by", "Admin");

      if (files) {
        const length = files.length > 3 ? 3 : files.length;
        for (let i = 0; i < length; i++) {
          formData.append(
            "attachment",
            files[i],
            files[i].name
          );
        }
      }

      // var data = {

      //   "subject": subject,
      //   "severity": Severity,
      //   "caseType": caseType,
      //   "description":description,
      //   "file": "req.files",
      //   "userId": userid,      
      //   "status": "Active",
      //   "created_by": "Admin"

      // }
      if (Object.values(files).map(f => f.name.match(/\.(png|jpg|jpeg|pdf|doc|docx|webp)$/) ? true : false).every(bool => bool === true)) {
        AddData(formData).then(result => {
          if (result !== undefined) {

            setsubject("")
            setDescription("")
            setFiles([])
            setSeverity("")
            setcaseType("")
            alert("Successfully Added")
            navigate('/support-new')
          }

        })
      }

    }
  }

  const handleAttachFile = (e) => {
    const file = e.target.files;
    //check if file is greater than 3 then show error message else add files in attacheFiles array
    if (file.length + files.length > 3) {
      alert("You can upload maximum 3 files");
      const newFiles = [...file].slice(0, 3 - files.length);
      if (newFiles.length > 0)
        setFiles(prevState => ([...prevState, ...newFiles]))
    } else {
      //add file in attacheFiles array
      setFiles(prevState => ([...prevState, ...file]))
    }
    //setdata(prevState => ({ ...prevState, attechFiles: [...files] }))
  }

  const handleRemoveFile = (e, file) => {
    e.preventDefault();
    setFiles(prevState => prevState.filter(f => f.name !== file.name));
  }

  return (
    <>
      <Layout />
      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Support </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                <li className="breadcrumb-item active">Support</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">

          <div className="row">
            <div className="col-lg-6">
              <label>User</label>
              <select className="form-control" name="Status" value={userid} onChange={(e) => setUserid(e.target.value)} >
                <option value="">Select</option>
                {userList.map((obj, index) => (
                  <option key={index} value={obj._id}> {obj.firstName + " " + obj.lastName} ({obj.email})</option>
                ))
                }
              </select>
            </div>
            <div className="col-lg-2">
              <label>Severity</label>
              <select className="form-control" name="Severity" value={Severity} onChange={(e) => setSeverity(e.target.value)}>
                <option value="">Select</option>
                <option value="Normal">Normal</option>
                <option value="Urgent">Urgent</option>
                <option value="Low">Low</option>
              </select>
            </div>
            <div className="col-lg-2">
              <label>Case Type</label>
              <select className="form-control" name="caseType" value={caseType} onChange={(e) => setcaseType(e.target.value)}>
                <option value="">Select</option>
                <option value="Technical Issue">Technical Issue</option>
                <option value="Cancellation and Feature Request">Cancellation and Feature Request</option>
                <option value="Account Related Issue">Account Related Issue</option>
                <option value="Billing Related Issue">Billing Related Issue</option>

              </select>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-12">
              Subject
            </div>
            <div className="col-lg-12">

              <input type="text" className="form-control" placeholder="" value={subject} onChange={(e) => setsubject(e.target.value)} />

            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 p-1">
              Description  :
            </div>
            <div className="col-lg-12">
              <textarea name="postContent" rows={3} className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />

            </div>
          </div>
          <Form.Control
            onChange={e => handleAttachFile(e)}
            name="attachment"
            id="addcase"
            multiple
            type="file"
            className="d-none"
          />
          <Form.Label htmlFor="addcase" className="btn btn-primary mt-2">
            Add Attachment
          </Form.Label>
          <Form.Text className="mt-2 text-muted d-block">
            Only PNG, JPG, JPEG, PDF, WEBP, DOC and DOCX files are allowed.
          </Form.Text>
          {files.map((file, index) =>
            <div key={index}>
              {/* <input className="me-2" type="checkbox" defaultChecked onChange={e => !e.target.checked
                            ? setdata(prevState => ({ ...prevState, attechFiles: prevState.attechFiles.filter(files => files.lastModified !== file.lastModified) }))
                            : setdata(prevState => ({ ...prevState, attechFiles: [...prevState.attechFiles, file] }))} /> */}
              <span>{file.name}</span>
              &nbsp;
              <span className="small">{`${file.size / 1000}KB`}</span>
              &nbsp;
              {<a href="#" onClick={(e) => handleRemoveFile(e, file)}>
                <span>{file.isUploaded ? '✔' : '❌'}</span>
              </a>}
            </div>
          )}
          <div className="col-lg-2">
            <label >&nbsp;</label>
            <div>
              <button className="btn btn-primary" value={"Submit"} onClick={e => handleSubmit(e)} > Submit </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default AddCase;
