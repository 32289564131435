import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import APIHandler from "../../helper/APIHandler";
import Layout from "../layout/layout";
import Pagination from "../layout/Pagination";
import GroupSessionTopicDetails from "./GroupSessionTopicDetails";

const PageSize = 10;

const GroupSession = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [status, setStatus] = useState("All");
  const [searchBy, setSearchBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [topicData, setTopicData] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isShowTopicDetails, setIsShowTopicDetails] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (isSending) {
      setIsSending(false);
    }
    const p = status;
    setStatus(p);
    searchData(p, searchBy);
  }, [isSending]);

  const searchData = async (status, searchBy) => {
    try {
      setIsLoaded(true);
      const result = await APIHandler.get(
        `admin/group-sessions?status=${status}&searchKey=${searchBy}`
      );
      if (result.success) {
        const data = result?.data;
        console.log("data", data);
        if (Array.isArray(data)) {
          setTopicData(data);
          setErrorMessage("");
          setIsLoaded(false);
        } else {
          setTopicData([]);
          setErrorMessage("No data found");
          setIsLoaded(false);
        }
      } else {
        setTopicData([]);
        setErrorMessage("No data found");
        setIsLoaded(false);
      }
      location.state = null;
    } catch (error) {
      console.error("Error:", error);
      setIsLoaded(false);
      setTopicData([]);
      setErrorMessage("No data found");
    }
  };
  

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return topicData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, topicData]);

  const handleShowDetail = (e, topicData) => {
    e.preventDefault();
    setIsShowTopicDetails(true);
    setTopicId(topicData._id);
  };

  const handleHideDetail = () => {
    setIsShowTopicDetails(false);
    searchData(status, searchBy);

  };

  return (
    <>
      <Layout />
      {!isShowTopicDetails ? (
        <div className="content-wrapper my_wrapper">
          <div className="content-header">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Group Session</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Group Session</li>
                </ol>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="row">
              <div className="col-lg-2">
                <label>Topic</label>
                <select
                  className="form-control"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>

              <div className="col-lg-2">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mentor/Topic Name "
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                />
              </div>
              <div className="col-lg-2">
                <label>&nbsp;</label>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => setIsSending(true)}
                  >
                    <i className="fa fa-search"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary mt-4">
                  <div className="revenue_card_header">
                    <h3> {topicData.length} Topics Found</h3>
                  </div>
                  <div className="card-body">
                    {errorMessage ? (
                      <p>{errorMessage}</p>
                    ) : (
                      <>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>Topic Name</th>
                              <th>Mentor Name</th>
                              <th>Fee/Session</th>
                              <th>Sessions</th>
                              <th>Status</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoaded ? (
                              currentTableData.map((obj, index) => (
                                <tr key={index}>
                                  <td>
                                    {(currentPage - 1) * PageSize + index + 1}
                                  </td>
                                  <td>{obj?.topicName}</td>
                                  <td>{obj?.mentorName}</td>
                                  <td>
                                    ${obj?.sessions[0]?.amountPerSeatDollar ||
                                      0}
                                  </td>
                                  <td>{obj?.sessions.length}</td>
                                  <td>{obj?.status}</td>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={(e) =>
                                        handleShowDetail(e, obj)
                                      }
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7}>
                                  <div className="loader"></div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={topicData.length}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <GroupSessionTopicDetails
          topicId={topicId}
          hideDetails={handleHideDetail}
        />
      )}
    </>
  );
};

export default GroupSession;
