import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";
import Pagination from "../layout/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import "../HelpCenter/HelpCenter.css";
import { useNavigate } from "react-router";
import moment from "moment";

const BASE_URL = process.env.REACT_APP_API_URL;
let PageSize = 10;

const PlatformFeedback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == undefined ||
      localStorage.getItem("token") == ""
    ) {
      navigate("/");
    }
  }, []);

  const [modal, setModal] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [isLoaded, SetisLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [viewfeedbackData, setViewfeedbackData] = useState({});
  const [expandedContent, setExpandedContent] = useState({});
  const [showMoreContent, setShowMoreContent] = useState({});

  const getAllFeedbackData = async () => {
    SetisLoaded(false);
    const data = await axios.get(`${BASE_URL}/feedbacks`, {
      headers: {
        authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    console.log(data.data.data, "data");
    if (data.data.success === true) {
      setFeedbackList(data.data.data);

      SetisLoaded(true);
    }
  };

  useEffect(() => {
    getAllFeedbackData();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return feedbackList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, feedbackList]);

  const handleViewFeedback = (fd) => {
    setViewfeedbackData(fd.feedback);
    setExpandedContent({ [fd._id]: false });
    setShow(true);
  };

  const handleToggleContent = (feedbackId) => {};

  return (
    <>
      <Layout />
      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Platform Feedback</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Platform Feedback</li>
              </ol>
            </div>
          </div>
        </div>

        <section>
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <h3>Platform Feedback</h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>
                        S.No
                        </th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Feedback</th>
                        <th>Capture On</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {console.log(currentTableData)}
                      {isLoaded ? (
                        currentTableData !== null &&
                        currentTableData.length > 0 ? (
                          currentTableData.map((obj, index) => (
                            <tr key={obj._id}>
                              <td>{index + 1} </td>
                              <td>
                                {obj.user.firstName} {""} {obj.user.lastName}
                              </td>
                              <td>{obj.user.email}</td>

                              <td>
                                {showMoreContent[obj._id]
                                  ? obj.feedback
                                  : `${obj.feedback.slice(0, 70)}...`}
                                {!showMoreContent[obj._id] && (
                                  <button
                                    className="btn btn-link btn-sm"
                                    onClick={() => handleViewFeedback(obj)}
                                  >
                                    View More
                                  </button>
                                )}
                              </td>
                              <td>
                                {moment(obj.createdAt).format("DD-MM-YYYY")}
                              </td>
                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={feedbackList.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            Platform Feedback
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShow(false)}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
             <div className="mb-2 ">
             {viewfeedbackData}
            </div>
                
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default PlatformFeedback;
