import Routes from './Routes/routes';
import { ToastContainer } from 'react-toastify';
//bootstrap css



function App() {
  return (
    <div className="App">
      <Routes />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
