const Footer=()=>{
    return(<>

<footer className="main-footer">
    <strong>Copyright &copy; 2023 <a href="#">Learnet</a>.</strong>
    All rights reserved.
  
  </footer>
    
    </>);
}
export default Footer;