import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import { CKEditor } from "ckeditor4-react";
import  { useRef } from 'react';
import { useNavigate } from 'react-router'

import { decode as base64_decode, encode as base64_encode } from "base-64";
import Pagination from '../layout/Pagination';
const webUrl = process.env.REACT_APP_WEB_URL;
const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token")
let PageSize = 10;

const Faq = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.period : ""
    const [FaqData, setFaqData] = useState([]);
    const [isLoaded, SetisLoaded] = useState(true)

    const [srno, setSrno] = useState("");
    const [questionid, SetQuestionid] = useState("");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [updateAnswer, setUpdateAnswer] = useState(base64_decode(""));
    const [actionType, setActionType] = useState("Add");
    const [maxid, setMaxid] = useState("");
    const [tempvalue, Settempvalue] = useState("NA")
    const [isEdit,SetIsEdit]=useState(false)
    const editorRef = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {

        if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
            navigate('/')
        }
    },[] )

    useEffect(() => {

        FillFaq();

    }, [isLoaded])

   
   

    const FillFaq = async () => {

        var result = await APIHandler.get(`admin/faqs`)
        setFaqData(result.data)
        console.log("faq list ", result.data)
        setMaxid(result.data.length + 1)

    }

    const handleChange = (evt) => {
        setAnswer(evt.editor.getData());
    };

    const handleUpdateChange = (evt) => {
        SetIsEdit(true)
        setUpdateAnswer(evt.editor.getData());       
        
    };
    const AddbtnFaq =  () => {

        SetIsEdit(true)       
       
        SetIsEdit(false)
        setMaxid(FaqData.length + 1)
        setActionType("Add");
        setSrno("")
        setQuestion("");
        setAnswer(base64_decode(""));
       
        window.$("#modal_1").modal("show")
       
    }



    const EditFaq = async (id) => {
       
        SetIsEdit(false)
        setUpdateAnswer(base64_decode(""))
        setActionType("Update");
        SetQuestionid(id);
        window.$("#modal_2").modal("show")

        var result = await APIHandler.get(`admin/faqs/${id}`)
        // setSessionData(result.data)


        setSrno(result.data.sr)
        setQuestion(result.data.question);
        setUpdateAnswer(base64_decode(result.data.answer))
        console.log("Befor Assign value ", result.data.answer, base64_decode(result.data.answer))
     
        
    }
    const DeleteFaq = async (id) => {
        SetQuestionid(id);

       if( window.confirm('Are you sure ?'))
        {

            var config = {
                headers: {
                    'authorization': token,
                },
            };
            const result = await axios.delete(`${baseUrl}admin/faqs/${id}`, config)
            if (result != null) {
                FillFaq();
            }
        }
    }

    const HidePopup = async () => {

        window.$("#modal_1").modal("hide")
        window.$("#modal_2").modal("hide")
        setQuestion("");
        setAnswer("");
       
    }
    //add faq
    const AddFaqAPI = async (reqdata) => {
        SetisLoaded(false)
       
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.post(`${baseUrl}admin/faqs/`, JSON.stringify(reqdata), headers).then((response) => {
               
                SetIsEdit(false)
                setAnswer("");
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }
        SetisLoaded(true)
        alert("Question successfully added")

    }

    const UpdateFaqAPI = async (reqdata) => {
        SetisLoaded(false)

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.put(`${baseUrl}admin/faqs/${questionid}`, JSON.stringify(reqdata), headers).then((response) => {
                alert("Question successfully updated")
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return alert(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return alert("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return alert("Url not found")
                }
                else if (error.response.status === 500) {
                    return alert("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return alert('Something went wrong , Please check your internet connection.')
        }

        SetisLoaded(true)

    }
    const AddFaq = async () => {

        if (srno == undefined || srno == "")
            alert("Please enter Sr.") 
        else  if (srno< 0)
            alert("Please enter only positive number")  


        else if (question == undefined || question == "")
            alert("Please enter question ")
      
        else if (answer == undefined || answer == "")
            alert("Please enter answer")


        else {

            SetisLoaded(false)

            if (actionType === "Add") {
                var data = {
                    "sr": srno,
                    "question": question,
                    "answer": base64_encode(answer)
                }

                AddFaqAPI(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")

                        SetisLoaded(true)
                        setSrno("")
                        setQuestion("")
                        setAnswer(base64_decode(""));                       
                        SetIsEdit(false)
                        window.location.reload();
                        
                    }

                })
                
            }
            else {

                var data = {
                    "_id": questionid,
                    "sr": srno,
                    "question": question,
                    "answer": base64_encode(answer)
                }
                UpdateFaqAPI(data).then(result => {
                    if (result !== undefined) {

                        window.$("#modal_1").modal("hide")
                        setSrno("")
                        setQuestion("")
                        setAnswer("");
                        SetisLoaded(true)
                    }

                })

            }

        }
        FillFaq();
        SetIsEdit(false)
    }


    const UpdateFaq = async () => {

        window.$("#modal_2").modal("show")

        if (srno == undefined || srno == "")
            alert("Please enter sr no") 
        else  if (srno< 0)
            alert("Please enter positive no")  

        if (question == undefined || question == "")
            alert("Please enter question ")

        else if (updateAnswer == undefined || updateAnswer == "")
            alert("Please enter answer")


        else {

            SetisLoaded(false)




            var data = {
                "_id": questionid,
                "sr": srno,
                "question": question,
                "answer": base64_encode(updateAnswer)
            }
            UpdateFaqAPI(data).then(result => {
                if (result !== undefined) {

                    window.$("#modal_2").modal("hide")
                    setSrno("")
                    setQuestion("")
                    setAnswer("");
                    SetisLoaded(true)
                    setUpdateAnswer(base64_decode(""))
                    SetIsEdit(false)
                }

            })



        }
        FillFaq();

    }

    return (
        <>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">FAQ</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                                <li className="breadcrumb-item active">FAQ</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="revenue_card_header">
                                    Manage FAQ
                                </div>
                                <div className="card-body">
                                    <div className='fqaddbtn'>
                                        <button className="btn btn-primary" onClick={e => AddbtnFaq()}>Add Question </button>

                                    </div>
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr. </th>
                                                <th>Question</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                (FaqData !== null && FaqData.length > 0) ?
                                                    FaqData.map((obj, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{obj.question}</td>
                                                            <td><span className="links" onClick={e => EditFaq(obj._id)}>Edit </span></td>
                                                            <td><span className="links" onClick={e => DeleteFaq(obj._id)}>Delete </span></td>

                                                        </tr>
                                                    )) : "No Data Found"}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>

            {/* model Pay  */}
            <div class="modal fade"
                id="modal_1"
                tabIndex="-1"
                aria-hidden="true">
                <div class="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div class="modal-content">
                        {/* <!-- Modal header --> */}
                        <div class="modal-header">
                            <h4 class="modal-title text-center" id="modal_1">
                                <b> {actionType === "Add" ? "Add Question" : "Edit Question"}</b>
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={e => { window.$("#modal_1").modal("hide") }}
                                style={{ border: "none", background: "white" }}
                            >&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className='paymentdetails-row'>
                                <section className="content">
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            Sr : <input type="number" className="form-control" placeholder="" value={srno} onChange={(e) => setSrno(e.target.value)} />
                                        </div>
                                        <div className="col-lg-12 p-1">
                                            Question :
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea name="postContent" rows={2} className="form-control" placeholder="" value={question} onChange={(e) => setQuestion(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            Answer :
                                        </div>
                                        <div className="col-lg-12">

                                            <CKEditor initData={answer} onChange={handleChange}  />

                                            {/* <textarea name="postContent" rows={4} className="form-control" placeholder="" value={answer} onChange={(e) => setAnswer(e.target.value)} /> */}
                                        </div>
                                    </div>



                                    <div className="row">

                                        <div className="col-lg-12 p-1">
                                            <button className="btn btn-primary" onClick={e => HidePopup(e)} > Cancel </button> &nbsp;
                                            <button className="btn btn-primary" value={actionType} onClick={e => AddFaq(e)} > {actionType} </button>

                                        </div>
                                    </div>

                                </section>

                            </div>
                        </div>




                    </div>

                </div>

                {/* <!-- Form END --> */}
            </div>

            {/* update */}
            <div class="modal fade"
                id="modal_2"
                tabIndex="-1"
                aria-hidden="true">
                <div class="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div class="modal-content">
                        {/* <!-- Modal header --> */}
                        <div class="modal-header">
                            <h4 class="modal-title text-center" id="modal_2">
                                <b> Update FAQ</b>
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={e => { window.$("#modal_2").modal("hide") }}
                                style={{ border: "none", background: "white" }}
                            >&times;</button>
                        </div>
                        <div class="modal-body">
                            <div className='paymentdetails-row'>
                                <section className="content">
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            Sr : <input type="number" className="form-control" placeholder="" value={srno} onChange={(e) => setSrno(e.target.value)} />
                                        </div>
                                        <div className="col-lg-12 p-1">
                                            Question :
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea name="postContent" rows={2} className="form-control" placeholder="" value={question} onChange={(e) => setQuestion(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 p-1">
                                            Answer :
                                        </div>
                                        <div className="col-lg-12">
                                            {((updateAnswer !== "" && updateAnswer !== null) || isEdit) &&
                                                <CKEditor initData={updateAnswer} onChange={handleUpdateChange} />
                                            }

                                            {/* <textarea name="postContent" rows={4} className="form-control" placeholder="" value={answer} onChange={(e) => setAnswer(e.target.value)} /> */}
                                        </div>
                                    </div>



                                    <div className="row">

                                        <div className="col-lg-12 p-1">
                                            <button className="btn btn-primary" onClick={e => HidePopup(e)} > Cancel </button> &nbsp;
                                            <button className="btn btn-primary" value={actionType} onClick={e => UpdateFaq(e)} > {actionType} </button>

                                        </div>
                                    </div>

                                </section>

                            </div>
                        </div>




                    </div>

                </div>

                {/* <!-- Form END --> */}
            </div>
        </>
    )
}
export default Faq