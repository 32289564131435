import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import APIHandler from "../../helper/APIHandler";
import Pagination from "../layout/Pagination";
import { useNavigate } from "react-router";

const webUrl = process.env.REACT_APP_WEB_URL;
let PageSize = 10;

const Payments = () => {
  const location = useLocation();
  let periodfromdash =
    location.state !== undefined && location.state !== null
      ? location.state.PeriodDash
      : "";

  const [period, setPeriod] = useState("ThisYear");

  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [status, setStatus] = useState("");

  const [sessionid, setSessionid] = useState("");
  const [mentors, setMentors] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [payments, setPayments] = useState([]);
  const [totaltransaction, setTotalPayments] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isSending, setIsSending] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  const [actionType, setActionType] = useState("Add");
    const [transactionFor, setTransactionFor] = useState("");

  useEffect(() => {
    if (isSending) {
      setIsSending(false);
    }
    var p = period;
    if (periodfromdash !== null && periodfromdash !== undefined) {
      console.log("satus value ", periodfromdash);
      if (periodfromdash === "ThisYear") p = "ThisYear";
      if (periodfromdash === "ThisMonth") p = "ThisMonth";
      if (periodfromdash === "ThisWeek") p = "ThisWeek";
      if (periodfromdash === "PreviousYear") p = "PreviousYear";
      if (periodfromdash === "PreviousMonth") p = "PreviousMonth";
      if (periodfromdash === "PreviousWeek") p = "PreviousWeek";
    }
    setPeriod(p);
    searchData(p);
  }, [isSending]);

  useEffect(() => {
    const totalPayments = payments.length;
    const totalAmount = payments.reduce(
      (total, item) => total + item.amount,
      0
    );
    setTotalAmount(totalAmount);
    setTotalPayments(totalPayments);
  }, [payments]);

  const searchData = async (period) => {
    setIsLoaded(false);
    var result = await APIHandler.get(
      `admin/payments?period=${period}&startDate=${fromDate}&endDate=${toDate}&sessionType=${transactionFor}`
    );

    setMentors(result.data.mentors);
    setPayments(result.data.payments);

    setIsLoaded(true);
    location.state = null;
  };

  const ShowUpdatePayment = async (id) => {
    console.log("id ", id);
    window.$("#modalPay").modal("show");

    const foundPayment = payments.find((item) => item._id === id);

    console.log("found Payment", foundPayment);
    setSelectedPayment(foundPayment);
  };

  const UpdatePayment = async () => {
    setIsLoaded(false);
    try {
      const response = await APIHandler.put(
        `admin/payments/${selectedPayment._id}`,
        selectedPayment,
        true
      );

      const updatedPayment = response.data;
      // find and update item from payments array
      const updatedPayments = payments.map((item) => {
        if (item._id === updatedPayment._id) {
          // Update found payment fields except the user field
          const { user, ...updatedFields } = updatedPayment;
          return { ...item, ...updatedFields };
        }
        return item;
      });

      setPayments(updatedPayments);

      HidePopup();
      setIsLoaded(true);
    } catch (error) {
      alert(error.message);
    } finally {
    }
  };

  const DeletePayment = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const result = await APIHandler.delete(`admin/payments/${id}`);
        const paymentId = result.data._id;

        // Remove deleted payment from payments array
        const updatedPayments = payments.filter(
          (item) => item._id !== paymentId
        );
        setPayments(updatedPayments);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handlePaymentInputChanges = (e) => {
    setSelectedPayment({ ...selectedPayment, [e.target.name]: e.target.value });
  };

  const HidePopup = async () => {
    window.$("#modalPay").modal("hide");

    setSelectedPayment(null);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return payments.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, payments]);

  return (
    <>
      <Layout />

      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Payments</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Payments</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div className="col-lg-2">
              <label>Period</label>
              <select
                className="form-control"
                name="Period"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <option value="ThisWeek">This Week</option>
                <option value="ThisMonth">This Month</option>
                <option value="ThisYear">This Year</option>
                <option value="PreviousWeek">Last Week</option>
                <option value="PreviousMonth">Last Month</option>
                <option value="PreviousYear">Last Year</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
            {period === "Custom" && (
              <>
                <div className="col-lg-2">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From Date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-lg-2">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To Date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="col-lg-4">
              <label>Mentor</label>
              <select
                className="form-control"
                name="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                {mentors.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.firstName} {item.lastName} ({item.email})
                  </option>
                ))}
              </select>
            </div>

            <div className="col-lg-2">
              <label>Transaction For</label>
              <select
                className="form-control"
                value={transactionFor}
                onChange={(e) => setTransactionFor(e.target.value)}
              >
                <option value="">All</option>
                <option value="OneToOneSession">
                    One to One Session
                </option>
                <option value="GroupSession">
                    Group Session
                </option>
              </select>
            </div>

            <div className="col-lg-2">
              <label>&nbsp;</label>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => setIsSending(true)}
                >
                  <i className="fa fa-search"></i> Search{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <h3>Total Payment : {totaltransaction} </h3>
                  <h3>Total Paid Amount : ${totalAmount} </h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Transaction Date/Time </th>
                        <th>Transaction ID </th>
                        <th>Amount</th>

                        <th>Source</th>
                        <th>Mentor</th>
                        <th>Remarks</th>
                        {/*  <th>Action</th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoaded ? (
                        currentTableData !== null &&
                        currentTableData.length > 0 ? (
                          currentTableData.map((obj, index) => (
                            <tr>
                              <td>{(currentPage - 1) * 10 + index + 1}</td>

                              <td>
                                {moment
                                  .utc(obj.payoutDate)
                                  .format("DD MMM YYYY HH:mm A")}
                              </td>
                              <td>{obj.transactionId} </td>
                              <td>$ {obj.amount}</td>
                              <td>{obj.paymentMode}</td>
                              <td>
                                {obj.user.firstName} {obj.user.lastName} <br />(
                                {obj.user.email})
                              </td>
                              <td>{obj.remarks}</td>

                              {/* 
                                                        <td><span className="links" onClick={e => ShowUpdatePayment(obj._id)}>Edit</span> <span className="links" onClick={e => DeletePayment(obj._id)}>Delete</span></td>
 */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            {" "}
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={payments.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* model Pay  */}
      <div class="modal fade" id="modalPay" tabIndex="-1" aria-hidden="true">
        <div class="modal-dialog" style={{ maxWidth: "800px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="modalidpay">
                <b>Payment Record Entry</b>
              </h4>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#modalPay").modal("hide");
                }}
                style={{ border: "none", background: "white" }}
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="paymentdetails-row">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-4">Pay To :</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={
                          selectedPayment?.user.firstName +
                          " " +
                          selectedPayment?.user.lastName
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Amount :</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        name="amount"
                        className="form-control"
                        placeholder=""
                        value={selectedPayment?.amount}
                        onChange={(e) => handlePaymentInputChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Date :</div>
                    <div className="col-lg-8">
                      <input
                        type="date"
                        name="payoutDate"
                        className="form-control"
                        placeholder=""
                        value={moment(selectedPayment?.payoutDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => handlePaymentInputChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Payment Mode :</div>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        name="paymentMode"
                        value={selectedPayment?.paymentMode}
                        onChange={(e) => handlePaymentInputChanges(e)}
                      >
                        <option value="">Select</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Venmo">Venmo</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Transaction ID:</div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        name="transactionId"
                        className="form-control"
                        placeholder=""
                        value={selectedPayment?.transactionId}
                        onChange={(e) => handlePaymentInputChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">Remarks :</div>
                    <div className="col-lg-8">
                      <textarea
                        name="remarks"
                        rows={2}
                        className="form-control"
                        value={selectedPayment?.remarks}
                        onChange={(e) => handlePaymentInputChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => HidePopup(e)}
                      >
                        {" "}
                        Cancel{" "}
                      </button>{" "}
                      &nbsp;
                      <button
                        className="btn btn-primary"
                        value={actionType}
                        onClick={(e) => UpdatePayment()}
                      >
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Form END --> */}
      </div>
    </>
  );
};
export default Payments;
