import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Layout from "../layout/layout";
import { Link, useNavigate } from 'react-router-dom';

import axios from "axios";
import "./Support.css";
import { toast } from "react-toastify";
import APIHandler from '../../helper/APIHandler';

const token = localStorage.getItem("token")
const BASE_URL = process.env.REACT_APP_API_URL;

const CaseInfo = () => {
  const { case_id } = useParams();
  const [data, setdata] = useState({ description: "", attechFiles: [] });
  const [caseid, setcaseid] = useState("");
  const navigate = useNavigate();
  const [caseStatus, setCaseStatus] = useState("");
  const [files, setFiles] = useState([]);

  const [userName, setUserName] = useState("");
  const [tableData, setTableData] = useState(null);
  const [comment, setComment] = useState([]);

  const [modal, setModal] = useState({
    open: false,
    img_url: "",
  });



  useEffect(() => {
    if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
      navigate('/')
    }
  }, [])
  useEffect(() => {
    getDetails();

  }, []);

  const getDetails = async () => {
    axios
      .get(`${BASE_URL}allcase/get/${case_id}`, {
        headers: {
          Authorization: (localStorage.getItem("token"))
        },
      })
      .then((res) => {

        setcaseid(res.data.data.caseId)
        setTableData(res.data.data);
        setComment(res.data.data.comments);
        setUserName(res.data.data.user.firstName);
        setCaseStatus(res.data.data.status)

        console.log("Email ", tableData.user.email);

      })
      .catch((error) => console.log(error));
  };
  // console.log("first")
  const postCorrespondence = () => {
    if (data.description == undefined || data.description == null || data.description == "") {

      alert("Please Enter Description")
    }
    else {
      const formData = new FormData();
      formData.append("comment", data.description);
      formData.append(
        "fullName",
        "Admin"
      );

      if (data.attechFiles) {
        const length = data.attechFiles.length > 3 ? 3 : data.attechFiles.length;
        for (let i = 0; i < length; i++) {
          formData.append(
            "attechFiles",
            data.attechFiles[i],
            data.attechFiles[i].name
          );
        }
      }
      if (Object.values(data.attechFiles).map(f => f.name.match(/\.(png|jpg|jpeg|pdf|doc|docx|webp)$/) ? true : false).every(bool => bool === true)) {
        if (data.description) {
          axios
            .post(
              `${BASE_URL}supportReply/${case_id}`,
              formData,
              {
                headers: {
                  Authorization: (localStorage.getItem("token")),
                },
              }
            )
            .then((res) => {
              // console.log(res, "hello brother");
              toast.success("Case Added Successfully");
              setdata({
                description: "",
                attechFiles: "",
              });
              getDetails();
            })
            .catch((error) => console.log(error));
        }
      }

    }

  };

  const CloseCase = async (status) => {
    if (window.confirm('Are you sure ?')) {
      const headers = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': localStorage.getItem("token")
        }
      }
      var data = {
        "status": status
      }
      const response = await axios.put(`${BASE_URL}/admin/allcase/update/${caseid}`, JSON.stringify(data), headers).then((response) => {
        navigate('/support-new')
      }
      ).catch(error => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            return alert(error.response.data.message)
        }
        else if (error.response.status === 401) {
          return alert("You are not authorized to view this page")
        }
        else if (error.response.status === 404) {
          return alert("Url not found")
        }
        else if (error.response.status === 500) {
          return alert("Internal server error please try after sometime")
        }
      });

    }
    else {

    }

  }

  const handleAttachFile = (e) => {
    const file = e.target.files;
    //check if file is greater than 3 then show error message else add files in attacheFiles array
    if (file.length + files.length > 3) {
      alert("You can upload maximum 3 files");
      const newFiles = [...file].slice(0, 3 - files.length);
      if (newFiles.length > 0)
        setFiles(prevState => ([...prevState, ...newFiles]))
    } else {
      //add file in attacheFiles array
      setFiles(prevState => ([...prevState, ...file]))
    }
    setdata(prevState => ({ ...prevState, attechFiles: [...files] }))
  }

  const handleRemoveFile = (e, file) => {
    e.preventDefault();
    setFiles(prevState => prevState.filter(f => f.name !== file.name));
  }

  const date = new Date(tableData?.createdAt)
  return (
    <>
      <Layout />
      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Support </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                <li className="breadcrumb-item active">Support</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="revenue_card_header">
            <h3 className="case-info-forntheding">Case Info({tableData?.caseId})</h3>
          </div>
          <div className="row"></div>
          <div className="caseinfo-main border">

            <Row className="gap-2 mt-10">
              <Col sm>
                <div className="close-typer">

                  <button className="btn btn-primary" onClick={e => CloseCase(caseStatus == "Resolved" ? "Reopen" : "Resolved")} > {caseStatus == "Resolved" ? "Reopen" : "Resolve"} </button>
                  &nbsp;
                  <Link className="btn btn-primary" to={"/support-new"}> Back </Link>
                </div>
              </Col>
            </Row>
            <div className="caseinfo-table my-4 bordered">
              <Table className="mb-0" responsive borderless>
                <thead className="border-bottom border-2">
                  <tr>
                    <th>Case details <span className="pe-5"></span><span className="">({caseStatus == "Active" ? "Open" : caseStatus == "Reopen" ? "Reopened" : caseStatus})</span></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-flex-instatus"> <span>Subject</span> -
                      <span> {tableData?.subject}</span>
                    </td>


                    <td className="table-flex-instatus">
                      <span>Created Date</span> -
                      <span>{date.toLocaleString()}</span>
                    </td>

                    <td></td>
                  </tr>


                  <tr>
                    <td className="table-flex-instatus">
                      <span> Severity</span> -
                      <span>{tableData?.severity}</span>
                    </td>

                    <td className="table-flex-instatus">
                      <span> Opened By</span> -
                      <span> {tableData?.openedBy}</span>
                    </td>
                  </tr>


                  <tr>
                    <td className="table-flex-instatus">
                      <span>Case Type</span> -
                      <span>{tableData?.caseType}</span>
                    </td>
                    <td className="table-flex-instatus">
                      <span>Email </span> -
                      <span>{tableData?.user.email}</span>
                    </td>
                  </tr>




                </tbody>
              </Table>
            </div>
            <div className="border border-bottom-0">
              <div className="p-3 border-bottom">
                <span className="fs-5 mb-2 mt-3">Correspondence</span>     -      <span className="user-co" >{userName} {tableData?.user.lastName}</span>
              </div>
              <hr className="m-0" />
              {caseStatus !== "Resolved" &&
                <Row>
                  <Col className="correspondence-message">
                    <Form.Control
                      rows={5}
                      as="textarea"
                      onChange={(e) =>
                        setdata((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }))
                      }
                      name="description"
                      value={data.description}
                      className=" ps-2 rounded-0"
                      placeholder="Give your reply "
                    />
                    <Row className="mt-2">

                      <Col className="text-end addcase-two mt-3">
                        <Col>
                          <Form.Control
                            onChange={e => handleAttachFile(e)}
                            name="attachment"
                            id="addcase"
                            multiple
                            type="file"
                            className="d-none"
                          />
                          <Form.Label htmlFor="addcase" className="btn btn btn-primary">
                            Add Attachment
                          </Form.Label>

                          {files.map((file, index) =>
                            <div key={index}>
                              {/* <input className="me-2" type="checkbox" defaultChecked onChange={e => !e.target.checked
                            ? setdata(prevState => ({ ...prevState, attechFiles: prevState.attechFiles.filter(files => files.lastModified !== file.lastModified) }))
                            : setdata(prevState => ({ ...prevState, attechFiles: [...prevState.attechFiles, file] }))} /> */}
                              <span>{file.name}</span>
                              &nbsp;
                              <span className="small">{`${file.size / 1000}KB`}</span>
                              &nbsp;
                              <a href="#" onClick={(e) => handleRemoveFile(e, file)}>
                                <span>{file.isUploaded ? '✔' : '❌'}</span>
                              </a>
                            </div>
                          )}
                        </Col>
                        <a
                          type="button"
                          onClick={postCorrespondence}
                          className="btn btn btn-primary"
                        >
                          Send
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>}
              {/* <hr className="m-0" /> */}
              {comment.map((e) => {
                const newdate = new Date(e.createdAt)
                return (
                  <>
                    <Row className="table-row">
                      <Col className="pt-2 pb-4 ">
                        <p className="px-2">{e.comment}</p>
                        {e.attechFiles.map((url, key) => (
                          <div className="caseinfo-table-img d-flex justify-content-between overflow-auto gap-4 pe-3">
                            <a
                              href={`${url.path}`}
                              target="_bhadresh"
                            >
                              {" "}
                              View Attachment
                            </a>
                          </div>
                        ))}
                      </Col>
                      <Col
                        className="pt-4 border-end d-flex justify-content-center"
                        sm={3}
                      >
                        <span><b>{e.fullName}</b></span> -

                        <span>{newdate.toLocaleString()}</span>
                        <br />
                      </Col>

                    </Row>
                    <hr className="m-0" />
                  </>
                );
              })}

              <Row className="table-row ">

                <Col className="pt-2 pb-4 tttt">
                  <p className="px-2">{tableData?.description}</p>
                  {tableData?.file?.map((url, key) => (
                    <div className="caseinfo-table-img d-flex justify-content-between overflow-auto gap-4 pe-3">
                      <a
                        href={`${url.path}`}
                        target="_bhadresh"
                      >
                        {" "}
                        View Attachment
                      </a>
                    </div>
                  ))}
                </Col>

                <Col className="pt-2 pb-4 text-center border-end" sm={3}>
                  <div className="table-flex-instatus">
                    <span> <b>{userName}</b> </span> -

                    <span>{date.toLocaleString()}
                      {/* {console.log(tableData.createdAt?.toUTCString())} */}
                    </span>
                  </div>
                  <br />
                </Col>
              </Row>
              <hr className="m-0" />
            </div>
            <Modal
              className="img-modal"
              show={modal.open}
              onHide={() =>
                setModal((prevState) => ({ ...prevState, open: !prevState.open }))
              }
            >
              <Modal.Body>
                <img src={modal.img_url} className="modal_img" alt="image" />
              </Modal.Body>
            </Modal>
          </div>

        </section>
      </div>
    </>
  );
};

export default CaseInfo;
