import Header from "../layout/header";
import Footer from "../layout/footer";
import Sidebar from "../layout/sidebar";
import axios from "axios";
import { useState, useEffect } from "react";
import Layout from "../layout/layout";
import Ploatchart from "../../components/ploatchart";
import DonutChart from "../../components/Donutchart";
import APIHandler from "../../helper/APIHandler";
// import { toast } from 'react-toastify';
import { Row, Col, Card } from "react-bootstrap";
import { Donut, Legend, ResponsiveContainer } from "britecharts-react";
import Chart from "react-apexcharts";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { array, number } from "prop-types";
import Refunds from "../Reports/Refunds";
const token = localStorage.getItem("token");

const Dashboard = () => {
  const navigate = useNavigate();

  const today = moment().format("DD-MMM-YYYY");

  const [DashboardSummary, setDashboardSummary] = useState([]);
  const [DashboardPeriod, setDashboardPeriod] = useState([]);
  const [isLoaded, SetisLoaded] = useState(true);
  const [isLoadedPeriod, SetisLoadedPeriod] = useState(true);

  const [allUsers, setallUsers] = useState("");
  const [mentors, setmentors] = useState("");
  const [pendingRequest, setPendingRequest] = useState("");
  const [upcomingSession, setUpcomingSession] = useState("");
  const [openTickets, setOpenTickets] = useState("");
  const [dues, setDues] = useState("");
  const [uniqueSkills, setUniqueSkills] = useState("");

  const [transactions, setTransactions] = useState([]);
  const [settlementdues, setSettlementdues] = useState([]);
  const [openCases, SetopenCases] = useState("");

  const [Period, setPeriod] = useState("ThisYear");
  const [ToDate, setToDate] = useState(today);
  const [FromDate, setFromDate] = useState(today);

  const [collection, setCollection] = useState("");
  const [payments, setPayments] = useState("");
  const [refund, setRefund] = useState("");
  const [sessionBooked, setSessionBooked] = useState("");
  const [completedSessions, setCompletedSessions] = useState("");
  const [cancelledSessions, setCancelledSessions] = useState("");
  const [noShowSessions, setNoShowSessions] = useState("");
  const [registeredUsers, setRegisteredUsers] = useState("");
  const [pendingrefund, setPendingrefund] = useState("");

  //const history = useHistory();
  const handleRefundRequestClick = () => {
    navigate("/");
    console.log("handleRefundRequestClick");
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == undefined ||
      localStorage.getItem("token") == ""
    ) {
      navigate("/");
    } else {
      Summary();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      PeriondByData();
    }
  }, [Period, ToDate, FromDate]);

  const Summary = async () => {
    SetisLoaded(false);
    const result = await APIHandler.get(
      `admin/dashboard?period=${Period}&startDate=${FromDate}&endDate=${ToDate}`
    );
    setDashboardSummary(result.data.summary);

    setallUsers(result.data.summary.allUsers);
    setmentors(result.data.summary.mentors);
    setPendingRequest(result.data.summary.pendingRequest);
    setUpcomingSession(result.data.summary.upcomingSession);
    setOpenTickets(result.data.summary.openTickets);
    setDues(result.data.summary.dues);
    setUniqueSkills(result.data.summary.totalSkills);

    setCollection(result.data.periodicData.finalCollection);
    setPayments(result.data.periodicData.payment);
    setRefund(result.data.periodicData.refund);
    setSessionBooked(result.data.periodicData.bookedSessions);
    setCompletedSessions(result.data.periodicData.completedSessions);
    setCancelledSessions(result.data.periodicData.cancelledSessions);
    setNoShowSessions(result.data.periodicData.noShowSessions);
    setRegisteredUsers(result.data.periodicData.registeredUsers);
    setPendingrefund(result.data.summary.totalPendingRefundRequests);

    SetisLoaded(true);
  };
  const PeriondByData = async () => {
    SetisLoadedPeriod(false);
    const result = await APIHandler.get(
      `admin/dashboard/periodic-data?period=${Period}&startDate=${FromDate}&endDate=${ToDate}`
    );
    setDashboardPeriod(result.data);
    setCollection(result.data.finalCollection);
    setPayments(result.data.payment);
    setRefund(result.data.refund);
    setSessionBooked(result.data.bookedSessions);
    setCompletedSessions(result.data.completedSessions);
    setCancelledSessions(result.data.cancelledSessions);
    setNoShowSessions(result.data.noShowSessions);
    setRegisteredUsers(result.data.registeredUsers);
    SetisLoadedPeriod(true);
  };
  return (
    <>
      <div className="wrapper">
        <Layout />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Content Header (Page header) --> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card card-primary">
                    <div className="dashboard_card_header">
                      <div>
                        <h3>Summary </h3>
                      </div>
                      <div className="dashboard_card_header">
                        <h3> {today} </h3>
                      </div>
                    </div>

                    <div className="card-body">
                      {isLoaded ? (
                        DashboardSummary !== null &&
                        DashboardSummary !== undefined ? (
                          <>
                            <div className="row dashboard-row">
                              <div className="col-lg-3">
                                <Link to="/joinuser">
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {allUsers}
                                      </h3>
                                      <p> Users</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link
                                  to="/pendingrequest"
                                  state={{ status: "Approved" }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {mentors}
                                      </h3>
                                      <p>Mentors</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-3">
                                <Link
                                  to="/pendingrequest"
                                  state={{ status: "Pending" }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {pendingRequest}
                                      </h3>
                                      <p>Pending Request</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-3">
                                <Link to="/upcomingsession">
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {upcomingSession}
                                      </h3>
                                      <p>Upcoming Sessions</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className="row dashboard-row">
                              <div className="col-lg-3">
                                <Link to="/support-new">
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {openTickets}
                                      </h3>
                                      <p>Open Tickets</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link to="/payables">
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        $ {dues}
                                      </h3>
                                      <p>Payables</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-3">
                                <Link to="/skills">
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {uniqueSkills}
                                      </h3>
                                      <p>Unique Skills</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link /*  onClick={handleRefundRequestClick} */>
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {pendingrefund}
                                      </h3>
                                      <p> Pending Refund Requests </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>Data not found</div>
                        )
                      ) : (
                        <div className="loader"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card card-primary">
                    <div className="dashboard_card_header">
                      <div>
                        <h3>Periodic Data </h3>
                      </div>
                      <div className="dashboard_card_header">
                        <h3>
                          <select
                            className="form-control"
                            name="Period"
                            value={Period}
                            onChange={(e) => setPeriod(e.target.value)}
                          >
                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                            <option value="Custom">Custom</option>
                          </select>
                        </h3>
                        {Period === "Custom" && (
                          <>
                            <h3>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="From Date"
                                value={FromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                              />{" "}
                            </h3>
                            <h3>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="To Date"
                                value={ToDate}
                                onChange={(e) => setToDate(e.target.value)}
                              />{" "}
                            </h3>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="card-body">
                      {isLoadedPeriod ? (
                        DashboardPeriod !== null &&
                        DashboardPeriod !== undefined ? (
                          <>
                            <div className="row dashboard-row">
                              <div className="col-lg-3">
                                <Link
                                  to="/transactions"
                                  state={{ PeriodDash: Period }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        $ {collection}
                                      </h3>
                                      <p>Collection</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link
                                  to="/payables"
                                  state={{ PeriodDash: Period }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        $ {payments}
                                      </h3>
                                      <p>Payments</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-3">
                                <Link
                                  to="/refunds"
                                  state={{ PeriodDash: Period }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        $ {refund}
                                      </h3>
                                      <p>Refunds</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-3">
                                <Link
                                  to="/joinuser"
                                  state={{ PeriodDash: Period }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {registeredUsers}
                                      </h3>
                                      <p>User Registration</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className="row dashboard-row">
                              <div className="col-lg-3">
                                <Link
                                  to="/sessionbooked"
                                  state={{ PeriodDash: Period, StatusDash: "" }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {sessionBooked}
                                      </h3>
                                      <p>Session Booked</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link
                                  to="/sessionbooked"
                                  state={{
                                    PeriodDash: Period,
                                    StatusDash: "Completed",
                                  }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {completedSessions}
                                      </h3>
                                      <p>Session Completed</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>

                              <div className="col-lg-3">
                                <Link
                                  to="/sessionbooked"
                                  state={{
                                    PeriodDash: Period,
                                    StatusDash: "Cancelled",
                                  }}
                                >
                                  <div className="small-box mb-0 card-text">
                                    <div className="inner text-center">
                                      <h3 className="mb-0 text-primary">
                                        {" "}
                                        {cancelledSessions}
                                      </h3>
                                      <p>Session Cancelled</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>Data not found</div>
                        )
                      ) : (
                        <div className="loader"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <!-- /.content-wrapper --> */}
      </div>

      <Footer />
    </>
  );
};
export default Dashboard;
