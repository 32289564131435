import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faUsersRectangle,
  faCommentDollar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import APIHandler from "../../helper/APIHandler";
import { toast } from "react-toastify";
import EditTopicModal from "./GroupSessionTopicModal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EnrollmentModal from "./EnrollmentModal";

const GroupSessionTopicDetails = ({ topicId, hideDetails }) => {
  const navigate = useNavigate();
  const BaseUrl = process.env.REACT_APP_IMAGE_URL;
  const [sessionData, setSessionData] = useState([]);
  const [isEditTopicModalOpen, setIsEditTopicModalOpen] = useState(false);
  const [topicData, setTopicData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const totalSession = sessionData.length;
  const totalEnrollment = sessionData.reduce((acc, session) => {
    return acc + session.participants.length;
  }, 0);
  useEffect(() => {
    if (topicData) {
      setSessionData(topicData?.sessions);
    }
  }, [topicData]);

  useEffect(() => {
    getTopicDetails();
  }, [topicId]);

  const getTopicDetails = async () => {
    try {
      const response = await APIHandler.get(
        `admin/group-sessions/topicbyId/${topicId}`
      );
      if (response.success === true) {
        setTopicData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdateTopicStatus = async (e, status) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await APIHandler.put(
        `admin/group-sessions/topic/status/${topicData._id}`,
        {
          status,
        }
      );

      if (response.success === true) {
        toast.success("Topic is " + status + " successfully");
        getTopicDetails();
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  //popup for Reject
  const handleRejectTopic = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this topic",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          handleUpdateTopicStatus(e, "Rejected");
        } catch (error) {
          toast.error(error.message);
        }
      }
    });
  };

  //popup for Approve
  const handleApproveTopic = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to approve this topic",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          handleUpdateTopicStatus(e, "Approved");
        } catch (error) {
          toast.error(error.message);
        }
      }
    });
  };

  const handleEditTopicClick = (e) => {
    e.preventDefault();
    setIsEditTopicModalOpen(true);
  };

  const handleCloseAddSessionModal = () => {
    setIsEditTopicModalOpen(false);
    getTopicDetails();
  };

  const handleEnrollmentClick = (e, id) => {
    e.preventDefault();
    setSessionId(id);
    setIsEnrollmentModalOpen(true);
  };

  return (
    <div className="content-wrapper my_wrapper ">
      <section className="container">
        <div className="row g-4">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div>
              <div className=" d-flex justify-content-between align-items-center mt-3">
                <h4>Group Session Topic Details</h4>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={hideDetails}
                ></button>
              </div>

              {isLoading ? (
                <>
                  <div className="loader"></div>
                </>
              ) : (
                <div className="card-body">
                  <div className="row g-4 mt-2">
                    <div className="col-lg-9 col-md-12 mx-auto mb-3">
                      <h4 className="">
                        {topicData?.topicName} {""} &nbsp; &nbsp;{" "}
                      </h4>
                      <p className="text-small d-flex align-items-center ">
                        Mentor: {topicData?.mentorId?.firstName} {""}{" "}
                        {topicData?.mentorId?.lastName}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-12 mx-auto">
                      <div className="d-flex justify-content-end ">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handleEditTopicClick(e)}
                        >
                          Edit
                        </button>
                        {topicData?.status &&
                          topicData?.status === "Pending" && (
                            <>
                              <button
                                className="btn btn-success ms-2"
                                onClick={(e) => handleApproveTopic(e)}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-danger ms-2"
                                onClick={(e) => handleRejectTopic(e)}
                              >
                                Reject
                              </button>
                            </>
                          )}
                        {topicData?.status &&
                          topicData.status === "Rejected" && (
                            <button
                              className="btn btn-success ms-2"
                              onClick={(e) => handleApproveTopic(e)}
                            >
                              Approve
                            </button>
                          )}
                        {topicData?.status &&
                          topicData.status === "Approved" && (
                            <button
                              className="btn btn-danger ms-2"
                              onClick={(e) => handleRejectTopic(e)}
                            >
                              Reject
                            </button>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="row g-4 ">
                    <div className="d-flex mt-2">
                      <div className="col-lg-4 col-md-12 mx-auto p-3">
                        <img
                          src={`${BaseUrl}/${topicData?.coverImage}`}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-8 col-md-12 mx-auto p-3">
                        <div className="row mb-3">
                          <div className="col-sm-3">
                            <FontAwesomeIcon icon={faClock} className="me-2" />
                            {topicData?.sessions[0]?.sessionDuration !==
                            undefined
                              ? topicData?.sessions[0]?.sessionDuration
                              : "0 "}  Minutes
                          </div>
                          <div className="col-sm-3">
                            <FontAwesomeIcon
                              icon={faUsersRectangle}
                              className="me-2"
                            />
                            {totalSession} Sessions
                          </div>
                          <div className="col-sm-3">
                            <FontAwesomeIcon
                              icon={faCommentDollar}
                              className="me-2"
                            />
                            $
                            {topicData?.sessions[0]?.amountPerSeatDollar !==
                            undefined
                              ? topicData?.sessions[0]?.amountPerSeatDollar
                              : 0}{" "}
                            per seat
                          </div>
                          <div className="col-sm-3">
                            <FontAwesomeIcon icon={faUsers} className="me-2" />
                            {isNaN(totalEnrollment) ||
                            typeof totalEnrollment === "undefined" ||
                            totalEnrollment === null
                              ? 0
                              : totalEnrollment}{" "}
                            participants
                          </div>
                        </div>

                        <div>
                          <h4 className="mb-3">Details</h4>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: topicData?.details,
                            }}
                          />
                        </div>

                        <div className="mt-4">
                          <h4 className="mb-3">Tag</h4>
                          <ul className="list-inline mb-0 d-flex flex-wrap gap-2">
                            {topicData?.topicCoverages &&
                            topicData?.topicCoverages ? (
                              topicData.topicCoverages.map((coverage) => (
                                <li
                                  className="list-inline-item m-0"
                                  key={coverage}
                                >
                                  <a className="btn btn-outline-secondary">
                                    {coverage}
                                  </a>
                                </li>
                              ))
                            ) : (
                              <p>No tags available</p>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-4 ">
                    <div className="col-lg-12 col-md-12 mx-auto">
                      <div>
                        <h4 className="mb-3">Sessions {""} &nbsp; </h4>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <tbody>
                            {topicData?.sessions &&
                            topicData?.sessions?.length > 0 ? (
                              topicData?.sessions.map((session) => {
                                if (session.isDeleted === true) return null;
                                const startTime = moment(
                                  session.sessionFromTime,
                                  "HH:mm"
                                );
                                const endTime = moment(
                                  session.sessionToTime,
                                  "HH:mm"
                                );
                                const durationMinutes = endTime.diff(
                                  startTime,
                                  "minutes"
                                );

                                const absDurationMinutes =
                                  Math.abs(durationMinutes);
                                const durationMessage = `${absDurationMinutes} mins`;

                                return (
                                  <tr key={session._id}>
                                    <td>
                                      {moment(session.sessionDate).format(
                                        "ddd, DD MMM YYYY"
                                      )}
                                    </td>

                                    <td>
                                      {moment(
                                        session.sessionFromTime,
                                        "HH:mm"
                                      ).format("hh:mm A")}{" "}
                                      to{" "}
                                      {moment(
                                        session.sessionToTime,
                                        "HH:mm"
                                      ).format("hh:mm A")}{" "}
                                      ({moment.tz(session.timeZone).format("z")}
                                      )
                                    </td>
                                    {/* <td>{durationMessage}</td> */}
                                    <td>
                                    {session.sessionType}
                                        {""} Session {session.amountPerSeatDollar == 0 ?(
                                         <></>

                                        ) : (
                                          <>
                                          - $  {session.amountPerSeatDollar} Per Seat
                                          </>
                                        )}
                                    </td>

                                    <td> Max Seat - {session.noOfSeats}</td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={(e) =>
                                          handleEnrollmentClick(e, session._id)
                                        }
                                      >
                                        {session.participants.length
                                          ? `${session.participants.length} Enrollment`
                                          : "0 Enrollment"}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7">No sessions available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <EditTopicModal
          isOpen={isEditTopicModalOpen}
          handleClose={handleCloseAddSessionModal}
          topicData={topicData}
          onCanceled={() => setIsEditTopicModalOpen(false)}
        />
        <EnrollmentModal
          isOpen={isEnrollmentModalOpen}
          onClose={() => setIsEnrollmentModalOpen(false)}
          sessionId={sessionId}
        />
      </section>
    </div>
  );
};

export default GroupSessionTopicDetails;
