import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import APIHandler from "../../helper/APIHandler";
import Pagination from "../layout/Pagination";
import { useNavigate } from "react-router";
import { set } from "react-hook-form";

const webUrl = process.env.REACT_APP_WEB_URL;
const baseUrl = process.env.REACT_APP_API_URL;
let PageSize = 10;

const RefundRequests = () => {
  const { status } = useParams();
  const location = useLocation();
  //let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.PeriodDash : ""

  const [Status, setStatus] = useState("Pending");
  const [refundAmount, setRefundAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [transactionSource, setTransactionSource] = useState("PayPal");
  const [transactionDate, setTransactionDate] = useState("");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [requestAmount, setRequestAmount] = useState("");
  const [reason, setReason] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const [sessionid, setSessionid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [RefundData, setRefundData] = useState([]);

  const [isLoaded, SetisLoaded] = useState(true);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const [show, setShow] = useState(false);
    const [transactionFor, setTransactionFor] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (e) => {
    // setSessionid(e)

    /* const result = RefundData.find((item) => item._id === e);

        console.log("result", result)

        setTotalAmount(result.session?.amount);
        setRequestAmount(result.requestedAmount);
        setStatus(result.status);
        setRefundAmount(result.refund?.amount);
        setTransactionDate(moment(result.refund?.transactionDate).format("YYYY-MM-DD"))
        setTransactionNumber(result.refund?.transactionNo)
        setTransactionSource(result.refund?.source ? result.refund?.source : "PayPal")
        setReason(result.reason)
        setOtherDetails(result.refund?.remarks) */
    setShow(true);
  };

  useEffect(() => {
    searchData();
  }, [filterStatus]);

  const searchData = async () => {
    SetisLoaded(false);
    var result = await APIHandler.get(
      `admin/refund-request?status=${filterStatus}&sessionType=${transactionFor}`
    );

    console.log("User ", result.data);
    setRefundData(result.data);

    SetisLoaded(true);
    location.state = null;
  };

  const UpdateRefundReq = async () => {
    console.log("transactionSource", transactionSource);
    var result = await axios.patch(
      `${baseUrl}admin/refund-request/${sessionid}`,
      {
        status: Status,
        refundableAmount: refundAmount,
        requestedAmount: requestAmount,
        amount: refundAmount,
        transactionDate: transactionDate,
        transactionNo: transactionNumber,
        source: transactionSource,
        remarks: otherDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    console.log("result 2222", result);

    if (result.data.success) {
      toast.success(result.message, { position: toast.POSITION.TOP_CENTER });
      searchData();
      handleClose();
      // setStatus("");
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    console.log("refund data", RefundData.slice(firstPageIndex, lastPageIndex));
    return RefundData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, RefundData]);

  const RefundRequestComponent = () => {
    return (
      <>
        <section className="content">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="1">
              Status :
            </Form.Label>
            <Form.Select
              className="from-control"
              aria-label="Default select example"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Refunded">Refunded</option>
              <option value="Rejected">Rejected</option>
            </Form.Select>
          </Form.Group>
          <div className="col-lg-2">
            <label>Transaction For</label>
            <select
              className="form-control"
              value={transactionFor}
              onChange={(e) => setTransactionFor(e.target.value)}
            >
              <option value="">All</option>
              <option value="OneToOneSession">One to One Session</option>
              <option value="GroupSession">Group Session</option>
            </select>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  {/* <h3>Amount : {totalAmount} </h3> */}
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Session Details</th>
                        <th>Requested By</th>
                        <th>Status</th>
                        <th>Reason</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoaded ? (
                        currentTableData !== null &&
                        currentTableData.length > 0 ? (
                          currentTableData.map((obj, index) => (
                            <tr>
                              <td>{(currentPage - 1) * 10 + index + 1}</td>

                              <td>
                                {" "}
                                {obj.session?.sessionType} <br></br> [
                                {obj.session?._id}]{" "}
                              </td>
                              <td>
                                {obj.user?.firstName} {obj.user?.lastName}{" "}
                                <br /> {obj.user?.email}
                              </td>
                              <td>{obj.status}</td>
                              <td>{obj.reason}</td>
                              <td>
                                <div style={{ textAlign: "center" }}>
                                  <button
                                    class="btn btn-info"
                                    onClick={(e) => handleShow(obj._id)}
                                  >
                                    View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            {" "}
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={RefundData.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {console.log("show", show)}
        {show && (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Refund Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label>Requested Amount :</Form.Label>
                    <Form.Control
                      type="number"
                      name="requestAmount"
                      placeholder=""
                      value={requestAmount}
                      onChange={(e) => setRequestAmount(e.target.value)}
                      disabled={Status !== "Pending" ? true : false}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label>Reason :</Form.Label>
                    <Form.Select
                      name="reason"
                      className="form-control"
                      aria-label="Default select example"
                      value={reason}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Choose a reason</option>
                      <option value="Mentor Discount">Mentor Discount</option>
                      <option value="Technical Issue">Technical Issue</option>
                      <option value="Mentor Missed Class">
                        Mentor Missed Class
                      </option>
                      <option value="I am not satisfied">
                        I am not satisfied
                      </option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label>Session Amount :</Form.Label>
                    <Form.Control
                      name="totalAmount"
                      type="number"
                      placeholder=""
                      value={totalAmount}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Transaction Date : </Form.Label>
                    <Form.Control
                      name="transactionDate"
                      type="date"
                      placeholder=""
                      value={transactionDate}
                      onChange={(e) => setTransactionDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Transaction Number : </Form.Label>
                    <Form.Control
                      name="transactionNumber"
                      type="text"
                      placeholder=""
                      value={transactionNumber}
                      onChange={(e) => setTransactionNumber(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Refunded Amount : </Form.Label>
                    <Form.Control
                      name="refundAmount"
                      type="number"
                      placeholder=""
                      max={totalAmount}
                      min={1}
                      value={refundAmount}
                      onChange={(e) => setRefundAmount(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label>Source :</Form.Label>
                    <Form.Select
                      id="source"
                      name="transactionSource"
                      className="form-control"
                      aria-label="Default select example"
                      value={transactionSource}
                      onChange={(e) => setTransactionSource(e.target.value)}
                    >
                      <option value="PayPal">Paypal</option>
                      <option value="Venmo">Venmo</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-lg-6">
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label>Status :</Form.Label>
                    <Form.Select
                      name="Status"
                      className="form-control"
                      aria-label="Default select example"
                      value={Status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>

                      <option value="Refunded">Refunded</option>
                      <option value="Rejected">Rejected</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Other Details : </Form.Label>
                <Form.Control
                  name="otherDetails"
                  as="textarea"
                  placeholder=""
                  value={otherDetails}
                  onChange={(e) => setOtherDetails(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={UpdateRefundReq}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  return (
    <>
      <RefundRequestComponent />
    </>
  );
};
export default RefundRequests;
