import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import APIHandler from "../../helper/APIHandler";
import Pagination from "../layout/Pagination";
import { useNavigate } from "react-router";
import RefundRequests from "./RefundRequests";

const webUrl = process.env.REACT_APP_WEB_URL;
const baseUrl = process.env.REACT_APP_API_URL;
let PageSize = 10;

const Refunds = () => {
  const location = useLocation();
  let periodfromdash =
    location.state !== undefined && location.state !== null
      ? location.state.PeriodDash
      : "";

  const selectedTabFromDashboard = location.state?.selectedTab;

  const [selectedTab, setSelectedTab] = useState(
    selectedTabFromDashboard || "Refunds"
  );
  const [Period, setPeriod] = useState("ThisYear");

  const [ToDate, setToDate] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [SearchBy, setSearchBy] = useState("");
  const [Status, setStatus] = useState("");

  const [sessionid, setSessionid] = useState("");
  const [sessionType, setsessionType] = useState("");
  const [session, setSession] = useState("");
  const [user, setUser] = useState("");
  const [mentor, setMentor] = useState("");
  const [sessionTimeUser, setsessionTimeUser] = useState("");
  const [sessionTimeMentor, setsessionTimeMentor] = useState("");
  const [refundId, setRefundId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [RefundData, setRefundData] = useState([]);
  const [totaltransaction, setTotalTransaction] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isSending, setIsSending] = useState(false);
  const [isLoaded, SetisLoaded] = useState(true);

  const [transactionSource, setTransactionSource] = useState("PayPal");
  const [transactionDate, setTransactionDate] = useState("");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [requestAmount, setRequestAmount] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [refundAmount, setRefundAmount] = useState(0);
  const [transactionFor, setTransactionFor] = useState("");

  useEffect(() => {
    if (isSending) {
      setIsSending(false);
    }
    var p = Period;
    if (periodfromdash !== null && periodfromdash !== undefined) {
      console.log("satus value ", periodfromdash);
      if (periodfromdash === "ThisYear") p = "ThisYear";
      if (periodfromdash === "ThisMonth") p = "ThisMonth";
      if (periodfromdash === "ThisWeek") p = "ThisWeek";
      if (periodfromdash === "PreviousYear") p = "PreviousYear";
      if (periodfromdash === "PreviousMonth") p = "PreviousMonth";
      if (periodfromdash === "PreviousWeek") p = "PreviousWeek";
    }
    setPeriod(p);
    searchData(p, SearchBy);
    console.log(Period);
  }, [isSending]);

  const searchData = async () => {
    SetisLoaded(false);
    var result = await APIHandler.get(
      `admin/refunds?refundType=Direct&period=${Period}&search=${SearchBy}&status=${Status}&transactionFor=${transactionFor}&startDate=${FromDate}&endDate=${ToDate}`
    );
    setRefundData(result?.data);
    setTotalAmount(result?.data?.totalAmount);
    console.log("resultresult?.data?.totalAmount", result?.data?.totalTransaction);
    setTotalTransaction(result?.data?.totalTransaction);
    SetisLoaded(true);
    location.state = null;
  };

  //View Session Details
  const LoadSessionDetails = async (id) => {
    SetisLoaded(false);

    var result = RefundData.find((obj) => obj._id === id);
    console.log("result", result);
    //setSessionid(result.session?._id);
    setRefundId(result._id);
    setsessionType(result.session?.sessionType);
    setSession(result.session?.skill);
    console.log("result.status", result.status);
    setStatus(result?.status);
    setTotalAmount(result?.amount);
    setRequestAmount(result?.requestedAmount);
    setRefundAmount(
      result?.amount === 0 ? result?.requestedAmount : result?.amount
    );
    setTransactionDate(moment(result?.transactionDate).format("YYYY-MM-DD"));
    setTransactionNumber(result?.transactionNo);
    setTransactionSource(result?.source);
    setOtherDetails(result?.remarks);

    /* 
        setUser(result.data.mentees[0].firstName + " " + result.data.mentees[0].lastName)
        setMentor(result.data.mentor.firstName + " " + result.data.mentor.lastName)
        setsessionTimeUser(result.data.date)
        setsessionTimeMentor(result.data.mentorDate) */
    window.$("#modalformentor").modal("show");
    SetisLoaded(true);
  };

  const UpdateRefundReq = async () => {
    console.log("transactionSource", transactionSource);
    var result = await axios.put(
      `${baseUrl}admin/refunds/${refundId}`,
      {
        status: Status,
        refundableAmount: requestAmount,
        requestedAmount: requestAmount,
        amount: requestAmount,
        transactionDate: transactionDate,
        transactionNo: transactionNumber,
        source: transactionSource,
        remarks: otherDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    console.log("result 2222", result);

    if (result.data.success) {
      toast.success(result.message, { position: toast.POSITION.TOP_CENTER });
      searchData(Period, SearchBy);
      window.$("#modalformentor").modal("hide");
      // setStatus("");
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return RefundData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, RefundData]);

  return (
    <>
      <Layout />

      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <div className="left-slidboble">
                <div className="left-slidb col-sm-2">
                  <Link
                    onClick={() => setSelectedTab("Refunds")}
                    className={
                      selectedTab === "Refunds" ? "activetabb" : "reafund__tab"
                    }
                  >
                    Refund
                  </Link>
                </div>

                <div className="col-sm-3">
                  <Link
                    onClick={() => setSelectedTab("RefundRequest")}
                    className={
                      selectedTab === "RefundRequest"
                        ? "activetabb"
                        : "refund__tab"
                    }
                  >
                    Refund Requests
                  </Link>
                </div>
              </div>
            </div>
            {/*   <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/refunds" >Home</Link></li>
                            <li className="breadcrumb-item active">Refunds</li>
                        </ol>
                    </div> */}
          </div>
        </div>
        {selectedTab === "Refunds" ? (
          <section className="content">
            <div className="row">
              <div className="col-lg-2">
                <label>Period</label>
                <select
                  className="form-control"
                  name="Period"
                  value={Period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value="ThisWeek">This Week</option>
                  <option value="ThisMonth">This Month</option>
                  <option value="ThisYear">This Year</option>
                  <option value="PreviousWeek">Last Week</option>
                  <option value="PreviousMonth">Last Month</option>
                  <option value="PreviousYear">Last Year</option>
                  <option value="Custom">Custom</option>
                </select>
              </div>
              {Period === "Custom" && (
                <>
                  <div className="col-lg-2">
                    <label>From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={FromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label>To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={ToDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="col-lg-2">
                <label>Status</label>
                <select
                  className="form-control"
                  name="Status"
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Initiated">Initiated</option>
                  <option value="Pending">Pending</option>
                  <option value="Refunded">Refunded</option>

                </select>
              </div>
              <div className="col-lg-2">
                <label>Transaction For</label>
                <select
                  className="form-control"
                  value={transactionFor}
                  onChange={(e) => setTransactionFor(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="OneToOneSession">One to One Session</option>
                  <option value="GroupSession">Group Session</option>
                </select>
              </div>

              <div className="col-lg-2">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Learner / Session ID"
                  value={SearchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                />
              </div>
              <div className="col-lg-2">
                <label>&nbsp;</label>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => setIsSending(true)}
                  >
                    <i className="fa fa-search"></i> Search{" "}
                  </button>
                  {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary mt-4">
                  <div className="revenue_card_header">
                    <h3>Total :{totaltransaction} </h3>
                    {/* <h3>Amount : {totalAmount} </h3> */}
                  </div>
                  <div className="card-body">
                    <table className="table table-striped  table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Learner</th>
                          <th>Session Details</th>
                          <th>Mentor</th>
                          {/* <th>Amount Receive</th> */}
                          <th>Amount Refunde</th>
                          <th>Reason</th>
                          <th>Remark</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {isLoaded ? (
                          currentTableData !== null &&
                          currentTableData?.length > 0 ? (
                            currentTableData?.map((obj, index) => (
                              <tr>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                {/* <td> {obj.session.sessionType} <br/> [{obj.session._id}] </td> */}
                                <td>
                                  {obj?.session?.mentees[0]?.firstName}{" "}
                                  {obj?.session?.mentees[0]?.lastName} <br />(
                                  {obj?.session?.mentees[0]?.email})
                                </td>
                                {
                                  <td>
                                    {" "}
                                    {obj?.session?.sessionType} <br />
                                    {obj?.session?._id}{" "}
                                  </td>
                                }
                                <td>
                                  {obj?.session?.mentor?.firstName}{" "}
                                  {obj?.session?.mentor?.lastName} <br />(
                                  {obj?.session?.mentor?.email})
                                </td>
                                {/* <td> {obj?.session?.amount} </td>{" "} */}
                                <td> {obj?.requestedAmount} </td>{" "}
                               
                                <td>{obj?.session?.reason}</td>
                                <td>{obj?.remarks}</td>
                                <td>{obj?.status}</td>
                                <td>
                                  <div style={{ textAlign: "center" }}>
                                    <button
                                      className="btn btn-info"
                                      onClick={() =>
                                        LoadSessionDetails(obj?._id)
                                      }
                                    >
                                      {" "}
                                      View{" "}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>No data found</td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              {" "}
                              <div className="loader"></div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={RefundData.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <RefundRequests />
        )}
      </div>

      <div
        class="modal fade"
        id="modalformentor"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ maxWidth: "700px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            {isLoaded ? (
              <>
                <div class="modal-header">
                  <h4 class="modal-title" id="modalforprofile"></h4>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      window.$("#modalformentor").modal("hide");
                    }}
                    style={{ border: "none", background: "white" }}
                  >
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <section className="content">
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <p className="mb-0">
                            <b>Topices :</b>
                          </p>{" "}
                          <span> {sessionType}</span>
                        </div>

                        <div className="col-lg-6">
                          <p className="mb-0">
                            <b>Session :</b>
                          </p>{" "}
                          <span> {session}</span>
                        </div>
                      </div>
                      <div className="row"></div>

                      <div className="row">
                        <div className="col-lg-4">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="formPlaintextEmail"
                          >
                            <Form.Label>Refundable Amount :</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              value={requestAmount}
                              onChange={(e) => setRequestAmount(e.target.value)}
                              disabled={Status !== "Pending" ? true : false}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-lg-4">
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintextEmail"
                          >
                            <Form.Label>Session Amount :</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              value={totalAmount}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-lg-4">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="formPlaintextEmail"
                          >
                            <Form.Label>Status :</Form.Label> <br />
                            <Form.Select
                              className="form-control"
                              as={Col}
                              aria-label="Default select example"
                              value={Status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="Pending">Pending</option>
                              <option value="Initiated">Initiated</option>
                              <option value="Refunded">Refunded</option>
                              <option value="Rejected">Rejected</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Transaction Date : </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder=""
                              value={transactionDate}
                              onChange={(e) =>
                                setTransactionDate(e.target.value)
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Transaction Number : </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={transactionNumber}
                              onChange={(e) =>
                                setTransactionNumber(e.target.value)
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Refunded Amount : </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              max={totalAmount}
                              min={1}
                              value={refundAmount}
                              disabled={true}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formPlaintextEmail"
                          >
                            <Form.Label>Source :</Form.Label>
                            <br />
                            <Form.Select
                              className="form-control"
                              aria-label="Default select example"
                              value={transactionSource}
                              onChange={(e) =>
                                setTransactionSource(e.target.value)
                              }
                            >
                              <option value="PayPal">Paypal</option>
                              <option value="Venmo">Venmo</option>
                              <option value="Other">Other</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Other Details : </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder=""
                          value={otherDetails}
                          onChange={(e) => setOtherDetails(e.target.value)}
                        />
                      </Form.Group>
                    </section>
                    <div className="btn_tt">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.$("#modalformentor").modal("hide");
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        className="ms-4"
                        variant="primary"
                        style={{ marginLeft: "1rem" }}
                        onClick={UpdateRefundReq}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="loader"></div>
            )}
          </div>
          <div class="modal-footer d-flex justify-content-between"></div>
        </div>

        {/* <!-- Form END --> */}
      </div>
    </>
  );
};
export default Refunds;
