import axios from 'axios';
import ApiError from './ApiError';
const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_URL;

class APIHandler {
  //Get
  async get(uri, hasToken = true) {
    try {
      const apiResponse = await axios.get(`${baseUrl}${uri}`, getConfig(hasToken));
      return execute(apiResponse);

    }
    catch (err) {
      throw new ApiError(500, 'Something went wrong');
    }
  }

  async post(uri, data, hasToken = true) {

    try {

      const apiResponse = await axios.post(`${baseUrl}${uri}`, data || {}, getConfig(hasToken));
      return execute(apiResponse);
    }
    catch (err) {
      throw new ApiError(500, 'Something went wrong');
    }
  }

  async patch(uri, hasToken = true) {

    try {

      const apiResponse = await axios.patch(`${baseUrl}${uri}`, {}, getConfig(hasToken));
      return execute(apiResponse);
    }
    catch (err) {
      throw new ApiError(500, 'Something went wrong');
    }
  }

  async put(uri, data, hasToken = true) {

    try {
      const apiResponse = await axios.put(`${baseUrl}${uri}`, data || {}, getConfig(hasToken));
      return execute(apiResponse);
    }
    catch (err) {
      throw new ApiError(500, 'Something went wrong');
    }
  }

  async delete(uri, hasToken = true) {
    try {
      const config = getConfig(hasToken);
      const apiResponse = await axios.delete(`${baseUrl}${uri}`, config);
      return execute(apiResponse);
    } catch (err) {
      if (err.response) {
        // If the error has a response from the API, include its message in the ApiError
        throw new ApiError(err.response.status, err.response.data.message || 'Something went wrong');
      } else {
        // If there's no response, handle the error without specific API details
        console.error("Request error:", err.message);
        throw new ApiError(500, 'Something went wrong');
      }
    }
  }

  async loginByPassword(data, hasToken = true) {
    try {

      const response = await axios.post(`${this.state.ApibaseURL}/auth/loginbypassword`, data).then((response) => {
        return response
      }
      ).catch(error => {
        if (error.response.status === 400) {
          if (error.response.data.success === false)
            console.log(error.response.data.message)
        }
        else if (error.response.status === 401) {
          console.log("You are authorized ")
        }
        else if (error.response.status === 404) {
          console.log("Url not found")
        }
        else if (error.response.status === 500) {
          console.log("Internal server error please try after sometime")
        }
      });
      return response.data
    } catch (error) {
      console.log('Something went wrong , Please check your internet connection.')
    }
  }
}

function getConfig(hasToken) {
  const token = localStorage.getItem("token")
  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
  if (hasToken)
    config.headers.Authorization = token;
  return config;
}

function execute(apiResponse) {
  if (!apiResponse.data.success) {
    if (DEBUG)
      console.error(apiResponse.data.message);
    throw new ApiError(apiResponse.status, apiResponse.data.message)
  }

  if (DEBUG)
    console.log(apiResponse.data);
  return apiResponse.data;
}

export default new APIHandler();