import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link, useNavigate } from "react-router-dom";



import DatePicker from "react-date-picker";
import "./Support.css";
import Layout from "../layout/layout";
import axios from "axios";
import PaginationCompo from "./PaginationCompo";

const SupportNew = () => {
  const navigate = useNavigate();
  const [dates, setDates] = useState({ startDate: "", endDate: "" });
  const [filterSelect, setFilterSelect] = useState("");
  const [severity, setSeverity] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [caseType, setCaseType] = useState("");
  const [cases, setCases] = useState([]);
  const [casesType, setCasesType] = useState([]);
  const [search, setSearch] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 20,
  });

  const BASE_URL = process.env.REACT_APP_API_URL;
 
  useEffect(() => {

      if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
          navigate('/')
      }
  },[] )
  const getAllCase = async () => {
    axios
      .get(
        `${BASE_URL}admin/allcase/get/all`,
        {
          headers: {
            Authorization: (localStorage.getItem("token"))
          },
        }
      )
      .then((res) => {
        setCases(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setPaginatedData(
      cases.slice(
        (pagination.currentPage - 1) * pagination.itemsPerPage,
        pagination.currentPage * pagination.itemsPerPage
      )
    );
    setPagination((prevState) => ({
      ...prevState,
      totalPages: Math.ceil(cases.length / pagination.itemsPerPage),
    }));
  }, [cases, pagination.currentPage]);

  useEffect(() => {
    getAllCase();

    axios
      .get(`${BASE_URL}/admin/allcase/get/all`, {
        headers: {
          Authorization: (localStorage.getItem("token")),
        },
      })
      .then((res) => {
        var tempcasetye = []
        res.data.data.map(item => {
          if (!tempcasetye.includes(item.caseType))
            tempcasetye.push(item.caseType)
        })
        console.log("tempcase type-", tempcasetye)
        setCasesType(tempcasetye)

      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (severity) {
      axios
        .get(
          `${BASE_URL}/admin/allcase/get/all`,
          {
            headers: {
              Authorization: (localStorage.getItem("token")),
            },
          }
        )
        .then((res) =>
          // console.log(res.data.data.filter((e) => e.severity === severity))
          setCases(res.data.data.filter((e) => e.severity === severity))
        )
        .catch((error) => console.log(error));
    }
  }, [severity]);

  useEffect(() => {
    if (caseStatus) {
      axios
        .get(
          `${BASE_URL}/admin/allcase/get/all`,
          {
            headers: {
              Authorization: (localStorage.getItem("token")),
            },
          }
        )
        .then((res) =>
          setCases(res.data.data.filter((e) => e.status === caseStatus))
        )
        .catch((error) => console.log(error));
    }
  }, [caseStatus]);

  useEffect(() => {
    if (caseType) {
      axios
        .get(
          `${BASE_URL}/admin/allcase/get/all`,
          {
            headers: {
              Authorization: (localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          if (caseType === "All") {
            setCases(res.data.data)
          }
          else
            setCases(res.data.data.filter((e) => e.caseType === caseType))
        }

        )
        .catch((error) => console.log(error));
    }
  }, [caseType]);

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      axios
        .get(
          `${BASE_URL}/admin/allcase/get/all`,
          {
            headers: {
              Authorization: (localStorage.getItem("token")),
            },
          }
        )
        .then((res) =>
          setCases(
            res.data.data.filter((e) => {
              const itemDate = new Date(new Date(e.createdAt).getFullYear(),new Date(e.createdAt).getMonth(),new Date(e.createdAt).getDate());
              const startDate = new Date(dates.startDate);
              const endDate = new Date(dates.endDate);
              console.log('dates',itemDate,startDate,endDate)
              return (itemDate >= startDate && itemDate<=endDate);
            })
          )
        )
        .catch((error) => console.log(error));
    }
  }, [dates]);

  // useEffect(() => {
  //   getAllCase();
  // }, [pagination.currentPage]);

  const handleKeyPress = (e) => {
    //when serch button is click than the serch logis is active
    searchMyData();
  };

  const searchMyData = async () => {
    try {
      if (search) {
        if (search.trim()) {
          axios
            .get(
              `${BASE_URL}admin/allcase/search?subject=${search}`,
              {
                headers: {
                  Authorization: (localStorage.getItem("token"))

                },
              }
            )
            .then((res) => setCases(res.data.data))
            .catch((error) => console.log(error));
        }
      } else {
        // console.log("first")
        getAllCase();
      }
    } catch (error) { }
  };

  return (
    <>

      <Layout />


      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Support </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                <li className="breadcrumb-item active">Support</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="revenue_card_header">
            <h3>Support</h3>
          </div>
          <div className="">
            <div className="support-main border ">

              <div className="creat-case-btn">
                <Link
                  to="/add-new-case"
                  type="button"
                  className="btn btn-primary mt-4"
                >
                  Create Case
                </Link>
              </div>

              <Row className=" gap-3">
                <Col lg={3}>
                  <InputGroup className="support-search">
                    <Form.Control
                      onKeyUp={handleKeyPress}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="ps-0"
                      placeholder="Search"
                    />

                  </InputGroup>

                </Col>
                <Col>
                  <Row>
                    <Col xs={3}>
                      <InputGroup className="support-dropdown">
                        <DropdownButton
                          variant="outline-secondary"
                          title={filterSelect || "Select"}
                        >
                          <Dropdown.Item
                            onClick={(e) => setFilterSelect(e.target.innerHTML)}
                            href="#"
                          >
                            Date Range
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setFilterSelect(e.target.innerHTML)}
                            href="#"
                          >
                            Severity
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setFilterSelect(e.target.innerHTML)}
                            href="#"
                          >
                            Case Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setFilterSelect(e.target.innerHTML)}
                            href="#"
                          >
                            Case Type
                          </Dropdown.Item>
                        </DropdownButton>
                      </InputGroup>
                    </Col>


                    {filterSelect === "Date Range" && (
                      <Col>
                        <>

                          <div className="options d-flex align-items-center nowrap gap-4">
                            <div className="text-nowrap">
                              <span>From : </span>
                              <DatePicker
                                value={dates.startDate}
                                onChange={(date) =>
                                  setDates((prevState) => ({ ...prevState, startDate: date }))
                                }
                              />
                            </div>
                            <div>
                              <span className="text-nowrap">To : </span>
                              <DatePicker
                                value={dates.endDate}
                                onChange={(date) =>
                                  setDates((prevState) => ({ ...prevState, endDate: date }))
                                }
                              />
                            </div>
                          </div>

                        </>
                      </Col>
                    )}
                    {filterSelect === "Severity" && (
                      <Col xs={4}>
                        <InputGroup className="support-dropdown">
                          <DropdownButton
                            variant="outline-secondary"
                            title={severity || "Select Severity"}
                          >
                            <Dropdown.Item
                              onClick={(e) => setSeverity(e.target.innerHTML)}
                              href="#"
                            >
                              Normal
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => setSeverity(e.target.innerHTML)}
                              href="#"
                            >
                              Urgent
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => setSeverity(e.target.innerHTML)}
                              href="#"
                            >
                              Low
                            </Dropdown.Item>
                          </DropdownButton>
                        </InputGroup>
                      </Col>
                    )}
                    {filterSelect === "Case Type" && (
                      <Col xs={4}>
                        <InputGroup className="support-dropdown">
                          <DropdownButton
                            variant="outline-secondary"
                            title={caseType || "Select Case Type"}
                          >
                            <Dropdown.Item
                              onClick={(e) => setCaseType(e.target.innerHTML)}
                              href="#"
                            >
                              All
                            </Dropdown.Item>
                            {casesType.map((data) => (
                              <Dropdown.Item
                                onClick={(e) => setCaseType(e.target.innerHTML)}
                                href="#"
                              >
                                {data}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </InputGroup>
                      </Col>
                    )}
                    {filterSelect === "Case Status" && (
                      <Col xs={4}>
                        <InputGroup className="support-dropdown">
                          <DropdownButton
                            variant="outline-secondary"
                            title={caseStatus || "Select Status"}
                          >
                            <Dropdown.Item
                              onClick={(e) => setCaseStatus(e.target.innerHTML)}
                              href="#"
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => setCaseStatus(e.target.innerHTML)}
                              href="#"
                            >
                              Resolved
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => setCaseStatus(e.target.innerHTML)}
                              href="#"
                            >
                              Reopened
                            </Dropdown.Item>
                          </DropdownButton>
                        </InputGroup>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <div className="options d-flex align-items-center nowrap gap-4 mt-3">

              </div>
              <div className="support-table mt-3">
                <Table className="mb-0" striped hover responsive bordered>
                  <thead>
                    <tr>
                      <th>Date Created</th>
                      <th>Case ID</th>
                      <th>User</th>
                      <th>Subject</th>
                      <th>Severity</th>
                     
                      <th>Case Type</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="border-top-0">
                    {paginatedData.length === 0 ? (
                      <td colSpan={6} className="text-center">
                        No Data Found
                      </td>
                    ) : (
                      paginatedData.map((data) => (
                        <tr key={data._id}>
                          <td>{data.createdAt.slice(0, 10)}</td>
                          <td onClick={(e) => navigate(e.target.innerHTML)}>
                            <span>{data.caseId} </span>
                          </td>
                          <td>
                          {data.user.firstName} {data.user.lastName} ({data.user.email} )
                          </td>
                          <td>{data.subject.length >40 ?  data.subject.substring(0, 40) + "..."  : data.subject}
                          
                          </td>
                          <td>{data.severity}</td>
                         
                          <td>{data.caseType}</td>
                          <td>{data.status=="Active"?"Open":data.status=="Reopen"?"Reopened":data.status}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>

              <PaginationCompo
                setPagination={setPagination}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
              />
            </div>
          </div>
        </section>
      </div>


    </>
  );
};

export default SupportNew;
