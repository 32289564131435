import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";
import Pagination from "../layout/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Form, FormLabel } from "react-bootstrap";
import "./HelpCenter.css";
import useDrivePicker from "react-google-drive-picker";
import { useNavigate } from 'react-router'

const BASE_URL = process.env.REACT_APP_API_URL;
let PageSize = 10;

const HelpCenter = () => {

  const navigate = useNavigate()

  useEffect(() => {

      if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
          navigate('/')
      }
  },[] )

  
  const [documentId, setDocumentId] = useState("");
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState([]);
  const [count, setCount] = useState([]);
  const [isLoaded, SetisLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [addCategory, setAddCategory] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    data: {},
  });
  const [editPreviewImg, setEditPreviewImg] = useState();
  const [embedImg, setEmbedImg] = useState();
  const [openPicker] = useDrivePicker();
  const [editOpenPicker] = useDrivePicker();
  const previewImgRef = useRef();

  const handleGoogleDriveUpload = () => {
    openPicker({
      clientId:
        "277228600812-8857j836kt2s8flq5s13cjmdffq6d244.apps.googleusercontent.com",
      developerKey: "AIzaSyDldIWiNdCREUZHLm4K21rsipNkvEGnVvQ",
      viewId: "DOCS_IMAGES",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      customScopes: [
        "https://www.googleapis.com/auth/drive.metadata.full_control https://www.googleapis.com/auth/cloud-platform",
      ],
      scope: "",
      callbackFunction: (data) => {
        data.docs?.forEach(
          (res) => (
            setAddCategory((prevState) => ({
              ...prevState,
              attechFiles: res.id,
            })),
            setEmbedImg(res.id)
          )
        );
        setShow((prevState) => !prevState);
      },
    });
  };

  const handleEditGoogleDriveUpload = () => {
    editOpenPicker({
      clientId:
        "277228600812-8857j836kt2s8flq5s13cjmdffq6d244.apps.googleusercontent.com",
      developerKey: "AIzaSyDldIWiNdCREUZHLm4K21rsipNkvEGnVvQ",
      viewId: "DOCS_IMAGES",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      customScopes: ["https://www.googleapis.com/auth/devstorage.full_control"],
      scope:
        "https://www.googleapis.com/auth/drive.metadata.full_control https://www.googleapis.com/auth/cloud-platform",
      callbackFunction: (data) => {
        data.docs?.forEach(
          (res) => (
            setEditModal((prevState) => ({
              ...prevState,
              data: { ...prevState.data, attechFiles: res.id },
            })),
            setEmbedImg(res.id)
          )
        );
        setEditModal((prevState) => ({
          ...prevState,
          isOpen: !prevState.isOpen,
        }));
      },
    });
  };

  const getCategory = async () => {
    SetisLoaded(false);
    const data = await axios.get(`${BASE_URL}/Topics/AllTopic`);
    if (data.data.success === true) {
      setCategory(data.data.data);
      setCount(data.data.count);
      SetisLoaded(true);
    }
  };

  const HendelDelete = async (id) => {
    const data = await axios.delete(`${BASE_URL}/Topics/deleteTopic/${id}`);
    if (data.data.success === true) {
      toast.success("Category Deleted Successfully");
      getCategory();
      toggle();
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const openModalWithHeaderClass = (id) => {
    setDocumentId(id);
    toggle();
  };
  // console.log(category, "category");
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return category.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, category]);

  const handleImageUpload = (e) => {
    setAddCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.files[0],
    }));
    if (
      e.target.files[0] &&
      e.target.files[0]?.name.match(/\.(jpg|jpeg|png)$/)
    ) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImg(e.target.result);
      reader.readAsDataURL(e.target.files[0]);
    } else {
      toast.error("Please upload an Image file");
    }
  };

  const handleEditImageUpload = (e) => {
    setEditModal((prevState) => ({
      ...prevState,
      data: { ...prevState.data, [e.target.name]: e.target.files[0] },
    }));
    if (
      e.target.files[0] &&
      e.target.files[0]?.name.match(/\.(jpg|jpeg|png)$/)
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImg(e.target.result);
        setEditPreviewImg(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      toast.error("Please upload an Image file");
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (!addCategory.S_No) {
      toast.error("Serial number is empty");
    } else if (!addCategory.topicName) {
      toast.error("Category name is empty");
    } else {
      const formData = new FormData();
      formData.append("topicName", addCategory.topicName);
      formData.append("S_No", addCategory.S_No);
      formData.append("attechFiles", addCategory.attechFiles);
      axios
        .post(`${BASE_URL}/Topics/CreateMainTopic`, formData)
        .then((res) => {
          if (res.data.success === false) {
            toast.error(res.data.msg);
          } else {
            getCategory();
            toast.success("Category added successfully");
            setShow(false);
            setEmbedImg(null);
            setPreviewImg(null);
            setAddCategory([]);
          }
          // console.log(res)
        })
        .catch((err) => {
          toast.error("Something missing");
        });
    }
  };

  const handleEditCategory = (e) => {
    e.preventDefault();
    if (!editModal.data.S_No) {
      toast.error("Serial number is empty");
    } else if (!editModal.data.topicName) {
      toast.error("Category name is empty");
    } else {
      const formData = new FormData();
      formData.append("topicName", editModal.data.topicName);
      formData.append("S_No", editModal.data.S_No);
      formData.append("attechFiles", editModal.data.attechFiles);
      if (category.some(topic => topic.S_No === editModal.data.S_No)) {
        axios
          .put(`${BASE_URL}/Topics/updateTopic/${editModal.data._id}`, formData)
          .then((res) => {
            console.log(res.data, "data");
            if (res.data.success === true) {
              toast.success("Category updateded successfully");
              setEmbedImg(null);
              setPreviewImg(null);
              setEditModal({
                isOpen: false,
                data: {},
              });
              getCategory();
            } else {
              toast.error(res.data.msg);
            }
          })
          .catch((err) => {
            toast.error("Something missing");
          });
      } else {
        toast.error("Serial number doesn't exist")
      }
    }
  };

  return (
    <>
      <Layout />
      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Help Center</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Help Center</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => setShow(true)}>
            Add Category
          </button> 
        </div>
        <section>
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <h3>Categories</h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Categories</th>
                        <th>No. of Articles</th>
                        <th>Action</th>
                        {/* <th>Edit</th>
                        <th>Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {console.log(currentTableData)}
                      {isLoaded ? (
                        currentTableData !== null &&
                          currentTableData.length > 0 ? (
                          currentTableData.map((obj,index) => (
                            <tr key={obj._id}>
                              <td>{index + 1} </td>
                              <td>{obj.topicName}</td>
                              <td>
                                <Link to={`/article/${obj._id}`}>
                                  {count
                                    .map((id) => id[obj._id])
                                    .find((num) => num) || 0}
                                  &nbsp;
                                  {(count
                                    .map((id) => id[obj._id])
                                    .find((num) => num) || 0) <= 1
                                    ? "Article"
                                    : "Articles"}
                                </Link>
                              </td>

                              <td>
                                <Link
                                  onClick={() => (
                                    setEditModal({ isOpen: true, data: obj }),
                                    obj.topicImage?.path &&
                                    setPreviewImg(obj.topicImage.path),
                                    obj.topicImage?.path &&
                                    setEditPreviewImg(
                                      `${BASE_URL}/${obj.topicImage.path}`
                                    ),
                                    !obj.topicImage?.path &&
                                    setEmbedImg(obj.topicImage)
                                  )}
                                  className="mr-3"
                                >
                                  Edit
                                </Link>

                                <Link
                                  onClick={() =>
                                    openModalWithHeaderClass(obj._id)
                                  }
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={category.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        onHide={() => (
          setAddCategory([]),
          setShow((prevState) => !prevState),
          setEmbedImg(null),
          setPreviewImg(null)
        )}
      >
        <Modal.Header>
          <Modal.Title className="w-100 text-center">Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddCategory}>
            <div className="row d-flex align-items-center">
              <Form.Label className="mb-0 mr-3">S.No</Form.Label> <br/>
              <Form.Control
                className="w-25"
                type="text"
                name="S_No"
                value={addCategory.S_No}
                onChange={(e) =>
                  setAddCategory((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
                  }))
                }
              />
            </div>
            <div>
              <Form.Label className="mt-3">Category Name</Form.Label>
              <Form.Control
                className="w-75"
                type="text"
                name="topicName"
                value={addCategory.topicName}
                onChange={(e) =>
                  setAddCategory((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            </div>
            <div className="d-flex flex-column">
              <Form.Label className="mt-3">Category Icon</Form.Label>
              <Form.Control
                className="d-none"
                type="file"
                id="file-upload"
                name="attechFiles"
                onChange={handleImageUpload}
              />
              <div className="d-flex justify-content-between w-75">
                {/* {embedImg ? (
                  <div className="ifram-main position-relative">
                    <img
                      height="64"
                      width="64"
                      src={`https://drive.google.com/uc?export=view&id=${embedImg}`}
                      alt="google-upload"
                    />
                    <span
                      onClick={() => setEmbedImg(null)}
                      className="position-absolute iframe-close"
                    >
                      X
                    </span>
                  </div>
                ) : ( */}
                <>
                  {previewImg ? (
                    <FormLabel
                      ref={previewImgRef}
                      className="preview-img mb-0 position-relative"
                    >
                      <img src={previewImg} alt="uploaded-img" />
                      <span
                        onClick={() => (
                          setPreviewImg(null),
                          (previewImgRef.current.className = "d-none")
                        )}
                        className="position-absolute close-icon"
                      >
                        X
                      </span>
                    </FormLabel>
                  ) : (
                    <FormLabel
                      className="file-upload mb-0"
                      htmlFor="file-upload"
                    >
                      <img src="/images/upload-files.png" alt="file-upload" />
                    </FormLabel>
                  )}
                </>
                {/* )
                } */}
                {/* {(!previewImg && !embedImg) && <img onClick={handleGoogleDriveUpload} width="64" height="64" src="/images/google-drive.png" alt="google-drive" />} */}
              </div>
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <button
                className="mr-3 btn btn-primary"
                type="button"
                onClick={() => (
                  setAddCategory([]),
                  setShow(false),
                  setEmbedImg(null),
                  setPreviewImg(null)
                )}
              >
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={editModal.isOpen}
        onHide={() => (
          setEditModal({ data: {}, isOpen: false }),
          setEmbedImg(null),
          setPreviewImg(null),
          setEditPreviewImg(null)
        )}
      >
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            Update Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {console.log(editModal, "editModal")} */}
          <Form onSubmit={handleEditCategory}>
            <div className="d-flex align-items-center">
              <Form.Label className="mb-0 mr-3">S.No</Form.Label>
              <Form.Control
                className="w-25"
                type="text"
                name="S_No"
                value={editModal.data.S_No}
                onChange={(e) =>
                  setEditModal((prevState) => ({
                    ...prevState,
                    data: {
                      ...prevState.data,
                      [e.target.name]: e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
                    },
                  }))
                }
              // disabled
              />
            </div>
            <div>
              <Form.Label className="mt-3">Category Name</Form.Label>
              <Form.Control
                className="w-75"
                type="text"
                name="topicName"
                value={editModal.data.topicName}
                onChange={(e) =>
                  setEditModal((prevState) => ({
                    ...prevState,
                    data: {
                      ...prevState.data,
                      [e.target.name]: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="d-flex flex-column">
              <Form.Label className="mt-3">Category Icon</Form.Label>
              <Form.Control
                className="d-none"
                type="file"
                id="file-upload"
                name="attechFiles"
                onChange={handleEditImageUpload}
              />
              <div className="d-flex justify-content-between w-75">
                {/* {embedImg ? (
                  <div className="ifram-main position-relative">
                    <img
                      height="64"
                      width="64"
                      src={`https://drive.google.com/uc?export=view&id=${embedImg}`}
                      alt="google-upload"
                    />
                    <span
                      onClick={() => setEmbedImg(null)}
                      className="position-absolute iframe-close"
                    >
                      X
                    </span>
                  </div>
                ) : ( */}
                <>
                  {previewImg ? (
                    <FormLabel
                      ref={previewImgRef}
                      className="preview-img mb-0 position-relative"
                    >
                      <img src={editPreviewImg} alt="uploaded-img" />
                      <span
                        onClick={() => (
                          setEditModal((prevState) => ({
                            ...prevState,
                            data: { ...prevState.data, topicImage: null },
                          })),
                          (previewImgRef.current.className = "d-none"),
                          setPreviewImg(null)
                        )}
                        className="position-absolute close-icon"
                      >
                        X
                      </span>
                    </FormLabel>
                  ) : (
                    <FormLabel
                      className="file-upload mb-0"
                      htmlFor="file-upload"
                    >
                      <img src="/images/upload-files.png" alt="file-upload" />
                    </FormLabel>
                  )}
                </>
                {/* )} */}
                {/* {(!previewImg && !embedImg) && <img onClick={handleEditGoogleDriveUpload} width="64" height="64" src="/images/google-drive.png" alt="google-drive" />} */}
              </div>
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <button
                className="mr-3 btn btn-primary"
                type="button"
                onClick={() => (
                  setEditModal({ data: {}, isOpen: false }),
                  setEmbedImg(null),
                  setPreviewImg(null),
                  setEditPreviewImg(null)
                )}
              >
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                Update
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal show={modal} onHide={toggle}>
        <Modal.Header onHide={toggle}>
          <h4 className="modal-title">Delete Category</h4>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mt-0">Are you sure ? </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={toggle}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => HendelDelete(documentId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpCenter;
