import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Form, FloatingLabel, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import axios from "axios";
import { toast } from 'react-toastify';
import Layout from "../layout/layout";
import APIHandler from '../../helper/APIHandler';
import Pagination from '../layout/Pagination';
import { useNavigate } from 'react-router'
const webUrl = process.env.REACT_APP_WEB_URL;
const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token")

let PageSize = 10;

const Joinuser = () => {

    const location = useLocation();
    let periodfromdash = (location.state !== undefined && location.state !== null) ? location.state.PeriodDash : ""


    const [Period, setPeriod] = useState("ThisYear");

    const [ToDate, setToDate] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [SearchBy, setSearchBy] = useState("");

    const [currentPage, setCurrentPage] = useState(1);


    const [JoinData, setJoinData] = useState([]);

    const [isSending, setIsSending] = useState(false)
    const [isLoaded, SetisLoaded] = useState(true)

    // const handleChangeInJoinData = (id, newItem) => {
    //     // Find the index of the item to update
    //     const index = items.findIndex(item => item._id === id);
    //     if (index !== -1) {
    //       // Create a new array with the updated item
    //       const newItems = [...items];
    //       newItems[index] = newItem;
    //       // Update the state with the new array
    //       setJoinData(newItems);
    //     }
    //   };
    const navigate = useNavigate();
    useEffect(() => {

        if (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == "") {
            navigate('/')
        }
    }, [])

    useEffect(() => {

        if (isSending) {
            setIsSending(false);

        }
        var p = Period;
        if (periodfromdash !== null && periodfromdash !== undefined) {
            console.log('satus value ', periodfromdash)
            if (periodfromdash === 'ThisYear')
                p = "ThisYear"
            if (periodfromdash === 'ThisMonth')
                p = "ThisMonth"
            if (periodfromdash === 'ThisWeek')
                p = "ThisWeek"
            if (periodfromdash === 'PreviousYear')
                p = "PreviousYear"
            if (periodfromdash === 'PreviousMonth')
                p = "PreviousMonth"
            if (periodfromdash === 'PreviousWeek')
                p = "PreviousWeek"


        }
        setPeriod(p);
        searchData(p, SearchBy)
        console.log(Period);

    }, [isSending]);



    const searchData = async (Period, SearchBy) => {

        SetisLoaded(false)
        var result = await APIHandler.get(`admin/users/joined-user?period=${Period}&start_date=${FromDate}&end_date=${ToDate}&searchKey=${SearchBy}`)

        console.log("User ", result.data)
        setJoinData(result.data);

        SetisLoaded(true)
        location.state = null
    }
   

    // Block


    const ClickUserBlock = async (id, status, upcomingSession) => {
       
        if (upcomingSession > 0) {
            toast.error("Cannot block mentor due to pending sessions")
            alert("Cannot block mentor due to pending sessions")
        }
        else {


            var userstatus = "";
            var data
            if (status === "Unblock") {
                data = {
                    "status": "false"
                }
            }
            else {
                data = {
                    "status": "true"
                }

            }



            UserBlock(data, id).then(result => {
                if (result !== undefined) {

                    SetisLoaded(true)

                    searchData(Period, SearchBy)
                    toast.success("Successfully Updated")
                }

            })

        }
    }
    const UserBlock = async (reqdata, id) => {

        SetisLoaded(false)
        if (window.confirm('Are you sure?')) {

            try {
                const headers = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': token
                    }
                }
                const response = await axios.patch(`${baseUrl}admin/users/${id}/block-status`, JSON.stringify(reqdata), headers).then((response) => {
                    return response
                }
                ).catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return alert(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return alert("You are not authorized to view this page")
                    }
                    else if (error.response.status === 404) {
                        return alert("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return alert("Internal server error please try after sometime")
                    }
                });
                return response.data
            } catch (error) {
                return alert('Something went wrong , Please check your internet connection.')
            }
            SetisLoaded(true)
        }
        else {
            SetisLoaded(true)
        }

    }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return JoinData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, JoinData]);


    return (<>
        <Layout />

        <div className="content-wrapper my_wrapper">
            <div className="content-header">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">All Users</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                            <li className="breadcrumb-item active">All Users</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="row">

                    <div className="col-lg-2">
                        <label>Period</label>
                        <select className="form-control" name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>

                            <option value="ThisWeek">This Week</option>
                            <option value="ThisMonth">This Month</option>
                            <option value="ThisYear">This Year</option>
                            <option value="PreviousWeek">Last Week</option>
                            <option value="PreviousMonth">Last Month</option>
                            <option value="PreviousYear">Last Year</option>
                            {<option value="Custom">Custom</option>}
                        </select>
                    </div>
                    {Period === "Custom" &&
                        <>
                            <div className="col-lg-2">
                                <label>From Date</label>
                                <input type="date" className="form-control" placeholder="From Date" value={FromDate} onChange={(e) => setFromDate(e.target.value)} />
                            </div>
                            <div className="col-lg-2">
                                <label>To Date</label>
                                <input type="date" className="form-control" placeholder="To Date" value={ToDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>

                        </>
                    }


                    <div className="col-lg-2">
                        <label>Search</label>
                        <input type="text" className="form-control" placeholder="Name / Email" value={SearchBy} onChange={(e) => setSearchBy(e.target.value)} />
                    </div>
                    <div className="col-lg-2">
                        <label >&nbsp;</label>
                        <div>
                            <button className="btn btn-primary" onClick={(e) => setIsSending(true)} ><i className="fa fa-search"></i> Search </button>
                            {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-primary mt-4">
                            <div className="revenue_card_header">
                                <h3>Total User(s): {JoinData.length} </h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped  table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Joined On </th>
                                            <th>Location</th>
                                            <th>Position/Job Title</th>
                                            <th>User Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isLoaded ? (
                                            (currentTableData !== null && currentTableData.length > 0) ?
                                                currentTableData.map((obj, index) => (
                                                    <tr>
                                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                                        <td><a href={webUrl + "/#/userprofile/" + obj._id} target="_blank">

                                                            {obj.firstName} {obj.lastName}
                                                        </a> </td>

                                                        <td>{obj.email} </td>
                                                        <td>
                                                            {moment(obj.createdAt).format("YYYY/MM/DD hh:mm A")}
                                                        </td>
                                                        <td>{obj.location}</td>
                                                        <td>{obj.currentJobTitle}</td>

                                                        <td>{obj.isMentor ? "Mentor" : "Learner"}  </td>
                                                        <td>{
                                                            (obj.isBlocked) ?
                                                                <button class="btn btn-success acction-btn" onClick={e => ClickUserBlock(obj._id, "Unblock",obj.upcomingSessionCount)}>Unblock</button>
                                                                : <button class="btn btn-danger" onClick={e => ClickUserBlock(obj._id, "Blocked",obj.upcomingSessionCount)}>Block</button>
                                                        }</td>


                                                    </tr>
                                                )) : <tr><td colSpan={7}>No data found</td></tr>) : <tr><td colSpan={7}>  <div className="loader"></div></td></tr>}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={JoinData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>





    </>);
}
export default Joinuser;