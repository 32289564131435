import { BrowserRouter,HashRouter,Route,Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login';
import Joinuser from '../pages/Users/Joinuser';
import PendingRequest from '../pages/Users/PendingRequest';
import SessionBooked from '../pages/Users/SessionBooked';
import UpcomingSession from '../pages/Users/UpcomingSession';
import RejectedSession from '../pages/Users/RejectedSession';
import Transactions from '../pages/Reports/Transactions';
import Payments from '../pages/Reports/Payments';
import Payables from '../pages/Reports/Payables';
import Faq from '../pages/FAQ/Faq';

import MasterSkills from '../pages/Dashboard/MasterSkills';
import CrawlingSetting from '../pages/Dashboard/CrawlingSetting';
import HelpCenter from '../pages/HelpCenter/HelpCenter';
import Article from '../pages/HelpCenter/Article';

import SupportNew from '../pages/SupportNew/SupportNew';
import AddCase from '../pages/SupportNew/AddCase';
import CaseInfo from '../pages/SupportNew/CaseInfo';
import Refunds from '../pages/Reports/Refunds';
import Sidemenu from '../components/Sidemenu';

import RefundRequests from '../pages/Reports/RefundRequests';
import PromotionCode from '../pages/promotionCode';
import PlatformFeedback from '../pages/PlatformFeedback.js/PlatformFeedback';
import GroupSession from '../pages/GroupSession/GroupSession';

const     Alltheroutes=()=>{
    return(<>
     <HashRouter>
       {/* main component routes */}
        <Routes>
        <Route path="/" element={<Login />} />       
        <Route path="dashboard" element={<Dashboard />} />  
        <Route path="joinuser" element={<Joinuser />} />
        <Route path="pendingrequest" element={<PendingRequest />} />
        <Route path="sessionbooked" element={<SessionBooked />} />
        <Route path="rejectedsession" element={<RejectedSession />} />
        <Route path="upcomingsession" element={<UpcomingSession />} />
        <Route path='transactions' element={<Transactions/>}/>
        <Route path='payments' element={<Payments/>}/>
        <Route path='payables' element={<Payables/>}/>
        <Route path='faq' element={<Faq/>}/>
        <Route path='refunds' element={<Refunds/>}/>
        <Route path='refund-requests/:status' element={<RefundRequests/>}/>

        <Route path='promotion-code' element={<PromotionCode/>}/>

        <Route path='skills' element={<MasterSkills/>}/>
        <Route path='crawling-setting' element={<CrawlingSetting/>}/>
        <Route path="help-center" element={<HelpCenter />} />
        <Route path="article/:id" element={<Article />} />
        <Route path="support-new" element={<SupportNew/>}/>
        <Route path='add-new-case' element={<AddCase/>}/>
        <Route path='support-new/:case_id' element={<CaseInfo/>}/>
        <Route path='sidemenus' element={<Sidemenu/>}/>
        <Route path='platform-feedback' element={<PlatformFeedback/>}/>
        <Route path='group-session' element={<GroupSession/>}/>
        
        </Routes>
     
            
        </HashRouter>
    
    
    </>);

}
export default Alltheroutes