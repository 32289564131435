import React, { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { useNavigate } from 'react-router'


const PaginationCompo = ({ setPagination, currentPage, totalPages }) => {
    const items = [];

    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item onClick={e => setPagination(prevState => ({ ...prevState, currentPage: number }))} key={number} active={number === currentPage}>
                {number}
            </Pagination.Item>
        );
    }

    useEffect(() => {
        setPagination(prevState => ({ ...prevState, currentPage: 1 }))
    }, [totalPages])

    return (
        <Pagination className="mt-4 mb-0 justify-content-center overflow-auto">
            <Pagination.Prev onClick={() => currentPage !== 1 && setPagination(prevState => ({ ...prevState, currentPage: prevState.currentPage - 1 }))} />
            {items}
            <Pagination.Next onClick={() => currentPage !== totalPages && setPagination(prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }))} />
        </Pagination>
    )
}

export default PaginationCompo;