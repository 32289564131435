import { DropdownButton, InputGroup } from "react-bootstrap";
import Layout from "./layout/layout";
import { Link } from "react-router-dom";
import { useState } from "react";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useEffect } from "react";
import APIHandler from "../helper/APIHandler";
import moment from "moment";

const PromotionCode = () => {

    const [activeStatus, setActiveStatus] = useState("Active");
    const [promotionCodeList, setPromotionCodeList] = useState([]);
    const [selectedPromotionId, setSelectedPromotionId] = useState(null);

    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showAddModel, setShowAddModel] = useState(false);

    const [promotionType, setPromotionType] = useState("Percentage");
    const [amount, setAmount] = useState(null);

    //Key value pair for duration 
    const durationList = [
        { key: "once", value: "Once" },
        { key: "repeating", value: "Repeating" },
        { key: "forever", value: "Forever" }
    ];
    const initialPromotionCode = {
        "code": "",
        "coupon": {
            "percent_off": null,
            "duration": "repeating",
            "duration_in_months": 1
        }
    }
    const [promotionCode, setPromotionCode] = useState(initialPromotionCode);

    useEffect(() => {
        GetAllPromotionCode(activeStatus);
    }, []);

    const handleActiveStatusChanged = (e) => {
        setActiveStatus(e.target.text);

        GetAllPromotionCode(e.target.text);
    }

    const handleAddFormChanges = (e) => {
        if (e.target.name === "promotionType") {
            setPromotionType(e.target.value);
            setPromotionCode({ ...promotionCode, coupon: { ...promotionCode.coupon, percent_off: e.target.value === "Percentage" ? amount : null, amount_off: e.target.value === "Percentage" ? null : amount, currency: e.target.value === "Percentage" ? null : "usd" } });
        }
        else if (e.target.name === "amount") {
            setAmount(e.target.value);
            setPromotionCode({ ...promotionCode, coupon: { ...promotionCode.coupon, percent_off: promotionType === "Percentage" ? e.target.value : null, amount_off: promotionType === "Percentage" ? null : e.target.value, currency: promotionType === "Percentage" ? null : "usd" } });
        }
        else if (e.target.name === "duration") {
            setPromotionCode({ ...promotionCode, coupon: { ...promotionCode.coupon, duration: e.target.value } });
        }
        else if (e.target.name === "duration_in_months") {
            setPromotionCode({ ...promotionCode, coupon: { ...promotionCode.coupon, duration_in_months: e.target.value } });
        }
        else if (e.target.name === "code") {
            setPromotionCode({ ...promotionCode, code: e.target.value });
        }
        /* This is not good practice please do not follow the above code */
    }

    const GetAllPromotionCode = async (activeStatus) => {
        const url = `strip/promotion-code?active=${activeStatus === "Active" ? "true" : activeStatus === "Inactive" ? "false" : ""}`;
        const promotionCodes = await APIHandler.get(url);
        setPromotionCodeList(promotionCodes?.data || []);

        console.log("data received", promotionCodes)
    }

    const AddPromotionCode = async () => {
        try {
            //Add validation for all fields
            if (promotionCode.code === "") {
                alert("Please enter code");
                return;
            }
            if (promotionCode.coupon.percent_off === null && promotionCode.coupon.amount_off === null) {
                alert("Please enter off %");
                return;
            }
            if (promotionCode.coupon.duration === "") {
                alert("Please enter duration");
                return;
            }
            if (promotionCode.coupon.duration_in_months === "") {
                alert("Please enter duration in months");
                return;
            }
            else if (promotionCode.coupon.duration_in_months < 1) {
                alert("Please enter duration in months greater than 0");
                return;
            }

            if (promotionCode.coupon.percent_off) {
                promotionCode.coupon.percent_off = Number(promotionCode.coupon.percent_off);
                //Remove amount_off and currency from object
                delete promotionCode.coupon.amount_off;
                delete promotionCode.coupon.currency;
            }

            if (promotionCode.coupon.amount_off) {
                promotionCode.coupon.amount_off = Number(promotionCode.coupon.amount_off);
                //Remove percent_off from object
                delete promotionCode.coupon.percent_off;
            }

            if(promotionCode.coupon.duration === "forever"){
                //Remove duration_in_months from object
                delete promotionCode.coupon.duration_in_months;
            }

            console.log("promotionCode", promotionCode)

            const url = `strip/promotion-code`;
            const promotionCodes = await APIHandler.post(url, promotionCode);

            //Add new item to list
            const newList = [...promotionCodeList, promotionCodes.data];
            setPromotionCodeList(newList);

            setPromotionCode(initialPromotionCode);
            setShowAddModel(false);
        }
        catch (e) {
            console.log(e)
            alert(e.message)
        }
    }

    const DeletePromotionCode = async () => {
        try {
            const url = `strip/promotion-code/${selectedPromotionId}`;
            const promotionCodes = await APIHandler.delete(url);


            setPromotionCodeList(promotionCodes?.data || []);
            //Remove deleted item from list
            const newList = promotionCodeList.filter((obj) => obj.id !== selectedPromotionId);
            setPromotionCodeList(newList);

            setSelectedPromotionId(null);
        }
        catch (e) {
            console.log(e)
            alert(e.message)
        }
        finally {
            setShowDeleteModel(false);
        }
    }

    return (
        <>
            <Layout />
            <div className="content-wrapper my_wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Promotion Code</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/dashboard" >Home</Link></li>
                                <li className="breadcrumb-item active">Promotion Code</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-primary mt-4">
                                <div className="revenue_card_header">
                                    Promotion Code
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            {/* Drop down for Active status: All, Active, Inactive usign react-bootstrap*/}
                                            {/* <InputGroup className="support-dropdown">
                                                <DropdownButton
                                                    variant="outline-secondary"
                                                    title={activeStatus || "Select Status"}
                                                >
                                                    <DropdownItem onClick={handleActiveStatusChanged}>All</DropdownItem>
                                                    <DropdownItem onClick={handleActiveStatusChanged} >Active</DropdownItem>
                                                    <DropdownItem onClick={handleActiveStatusChanged}>Inactive</DropdownItem>
                                                </DropdownButton>
                                            </InputGroup> */}
                                        </div>
                                        <div className="col-lg-10 text-right">
                                            <div className="form-group">
                                                <button className="btn btn-primary" onClick={e => setShowAddModel(true)}>Add New </button>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr. </th>
                                                <th>Code</th>
                                                {/* <th>Type</th> */}
                                                <th>Duration</th>
                                                <th>Off %</th>
                                                {/* <th>Status</th> */}
                                                <th>Expiry</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                promotionCodeList !== null && promotionCodeList.length > 0 ?
                                                    promotionCodeList.map((obj, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{obj.code}</td>
                                                            {/* <td>{obj.coupon?.percent_off !== null ? "Precentage" : "Amount"}</td> */}
                                                            {/* <td>{obj.coupon.duration}</td> */}
                                                            {
                                                                durationList.find(d => d.key == obj.coupon.duration) !== undefined ?
                                                                    <td>{durationList.find(d => d.key == obj.coupon.duration).value}</td> : <td></td>
                                                            }
                                                            <td>{obj.coupon?.percent_off !== null ? obj.coupon?.percent_off + "%" : obj.coupon.amount_off + " " + obj.coupon.currency}</td>
                                                            {/*  <td>{obj.active === true ? "Active" : "Inactive"}</td> */}
                                                            <td>{obj.expires_at === null ? "Never" : moment(obj.expires_at).format("DD MMM YYYY")}</td>
                                                            <td><span className="links" onClick={e => { setSelectedPromotionId(obj.id); setShowDeleteModel(true) }} >Delete</span></td>
                                                        </tr>
                                                    )) :
                                                    <tr>
                                                        <td colSpan="6">No active coupon found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>
                    {showDeleteModel &&
                        /* Create React Model popop for confirmation of delete promotion code */
                        <div className="modal fade show" id="modal-default" style={{ display: "block", paddingRight: "17px" }} aria-modal="true" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Delete Promotion Code</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => setShowDeleteModel(false)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete this promotion code?</p>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={e => setShowDeleteModel(false)}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e => DeletePromotionCode()}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {showAddModel &&
                        /* Create React Model popop for confirmation of delete promotion code */
                        <div className="modal fade show" id="modal-default" style={{ display: "block", paddingRight: "17px" }} aria-modal="true" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Add Promotion Code</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => setShowAddModel(false)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Code</label>
                                                    <input type="text" name="code" className="form-control" placeholder="Enter Code" value={promotionCode.code} onChange={handleAddFormChanges} />
                                                </div>
                                            </div>
                                            {/*  <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Promotion Type</label>
                                                    <select className="form-control" name="promotionType" value={promotionType} onChange={handleAddFormChanges}>
                                                        <option value="Percentage">Percentage</option>
                                                        <option value="Amount">Amount</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Off %</label>
                                                    <input type="number" name="amount" className="form-control" placeholder="Enter Off" value={promotionCode.coupon?.percent_off !== null ? promotionCode.coupon?.percent_off : promotionCode.coupon.amount_off} onChange={handleAddFormChanges} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Duration</label>
                                                    <select className="form-control" name="duration" value={promotionCode.coupon.duration} onChange={handleAddFormChanges}>
                                                        <option value="once">Once</option>
                                                        <option value="repeating">Repeating</option>
                                                        <option value="forever">Forever</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                promotionCode.coupon.duration === "repeating" &&
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Duration in Months</label>
                                                        <input type="number" min={1} name="duration_in_months" className="form-control" placeholder="Enter Duration in Months" value={promotionCode.coupon.duration_in_months} onChange={handleAddFormChanges} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={e => setShowAddModel(false)}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={e => AddPromotionCode()}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </div>
        </>
    );
}

export default PromotionCode;