import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../layout/layout";
import APIHandler from "../../helper/APIHandler";
import Pagination from "../layout/Pagination";
import { useNavigate } from "react-router";
import { set } from "react-hook-form";

const webUrl = process.env.REACT_APP_WEB_URL;
let PageSize = 10;

const Transactions = () => {
  const location = useLocation();
  let periodfromdash =
    location.state !== undefined && location.state !== null
      ? location.state.PeriodDash
      : "";

  const [Period, setPeriod] = useState("ThisYear");
  const [ToDate, setToDate] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [SearchBy, setSearchBy] = useState("");
  const [Status, setStatus] = useState("");
  const [sessionid, setSessionid] = useState("");
  const [sessionType, setsessionType] = useState("");
  const [session, setSession] = useState("");
  const [user, setUser] = useState("");
  const [mentor, setMentor] = useState("");
  const [sessionTimeUser, setsessionTimeUser] = useState("");
  const [sessionTimeMentor, setsessionTimeMentor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TransactionsData, setTransactionsData] = useState([]);
  const [totaltransaction, setTotalTransaction] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isLoaded, SetisLoaded] = useState(true);
  const [transactionFor, setTransactionFor] = useState("");

  useEffect(() => {
    if (isSending) {
      setIsSending(false);
    }
    var p = Period;
    if (periodfromdash !== null && periodfromdash !== undefined) {
      console.log("satus value ", periodfromdash);
      if (periodfromdash === "ThisYear") p = "ThisYear";
      if (periodfromdash === "ThisMonth") p = "ThisMonth";
      if (periodfromdash === "ThisWeek") p = "ThisWeek";
      if (periodfromdash === "PreviousYear") p = "PreviousYear";
      if (periodfromdash === "PreviousMonth") p = "PreviousMonth";
      if (periodfromdash === "PreviousWeek") p = "PreviousWeek";
    }
    setPeriod(p);
    searchData(p, SearchBy);
    console.log(Period);
  }, [isSending]);

  const searchData = async (Period, SearchBy) => {
    SetisLoaded(false);
    var result = await APIHandler.get(
      `admin/transactions?status=${Status}&period=${Period}&startDate=${FromDate}&endDate=${ToDate}&searchKey=${SearchBy}&sessionType=${transactionFor}`
    );

    setTransactionsData(result?.data?.transactions);
    setTotalAmount(result?.data?.totalAmount);
    setTotalTransaction(result?.data?.totalTransaction);

    SetisLoaded(true);
    location.state = null;
  };

  //View Session Details
  const LoadSessionDetails = async (id, transactionID, sessionType) => {
    try {
      SetisLoaded(false);
      window.$("#modalformentor").modal("show");
   
      if (sessionType == "GroupSession") {
        const response = await APIHandler.get(
          `admin/group-sessions/transactions/details/${transactionID}`
        );
        setsessionType(sessionType);
        setSession(response.data?.topicName);
        setUser(response.data?.participantName);
        setMentor(response.data?.mentorName);
        setsessionTimeUser(response.data?.participantDateTime);
        setsessionTimeMentor(response.data?.mentorDateTime);
        SetisLoaded(true);
      } else {
        const result = await APIHandler.get(`admin/sessions/${id}`);

        setSessionid(id);
        setsessionType(result.data?.sessionType);
        setSession(result.data?.skill);
        setUser(
          result?.data?.mentees[0].firstName +
            " " +
            result?.data?.mentees[0]?.lastName
        );
        setMentor(
          result?.data?.mentor.firstName + " " + result?.data?.mentor?.lastName
        );
        setsessionTimeUser(result?.data?.date);
        setsessionTimeMentor(result?.data?.mentorDate);
        SetisLoaded(true);
      }
    } catch (error) {
      console.log("Error ", error);
      SetisLoaded(true);
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return TransactionsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, TransactionsData]);

  return (
    <>
      <Layout />

      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Transactions</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Transactions</li>
              </ol>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div className="col-lg-2">
              <label>Period</label>
              <select
                className="form-control"
                name="Period"
                value={Period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <option value="ThisWeek">This Week</option>
                <option value="ThisMonth">This Month</option>
                <option value="ThisYear">This Year</option>
                <option value="PreviousWeek">Last Week</option>
                <option value="PreviousMonth">Last Month</option>
                <option value="PreviousYear">Last Year</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
            {Period === "Custom" && (
              <>
                <div className="col-lg-2">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From Date"
                    value={FromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-lg-2">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To Date"
                    value={ToDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="col-lg-2">
              <label>Status</label>
              <select
                className="form-control"
                name="Status"
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="Completed">Completed</option>
                <option value="Failed">Failed</option>
              </select>
            </div>
            <div className="col-lg-2">
              <label>Transaction For</label>
              <select
                className="form-control"
                value={transactionFor}
                onChange={(e) => setTransactionFor(e.target.value)}
              >
                <option value="">All</option>
                <option value="OneToOneSession">
                    One to One Session
                </option>
                <option value="GroupSession">
                    Group Session
                </option>
              </select>
            </div>

            <div className="col-lg-3">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder="Transaction ID / Session ID"
                value={SearchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            <div className="col-lg-2">
              <label>&nbsp;</label>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => setIsSending(true)}
                >
                  <i className="fa fa-search"></i> Search{" "}
                </button>
                {/* <button className="btn btn-primary ml-3"> <i className="fa fa-print"></i> </button> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <h3>Total Transactions :{totaltransaction} </h3>
                  <h3>Amount : {totalAmount} </h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Transaction Date/Time </th>
                        <th>Transaction ID </th>
                        <th>Amount</th>

                        <th>Status</th>
                        <th>Performed By</th>
                        <th>Session Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoaded ? (
                        currentTableData !== null &&
                        currentTableData?.length > 0 ? (
                          currentTableData?.map((obj, index) => (
                            <tr>
                              <td>{(currentPage - 1) * 10 + index + 1}</td>

                              <td>
                                {moment
                                  .utc(obj?.transactionDate)
                                  .format("DD MMM YYYY HH:mm A")}
                              </td>
                              <td>{obj?._id} </td>
                              <td>{obj?.amount - (obj?.discount || 0)}</td>
                              <td>{obj?.transactionStatus}</td>

                              <td>
                                {obj?.user?.firstName} {obj?.user.lastName}
                              </td>

                              <td>
                                {obj?.session?.sessionType}
                                <br />[{obj.session._id}]
                              </td>

                              <td>
                                <div style={{ textAlign: "center" }}>
                                  <button
                                    class="btn btn-info"
                                    onClick={(e) =>
                                      LoadSessionDetails(
                                        obj?.session._id,
                                        obj?._id,
                                        obj?.session?.sessionType
                                      )
                                    }
                                  >
                                    View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            {" "}
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={TransactionsData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        class="modal fade"
        id="modalformentor"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ maxWidth: "700px" }}>
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            {isLoaded ? (
              <>
                <div class="modal-header">
                  <h4 class="modal-title" id="modalforprofile">
                    {/* <b>Session ID : {sessionid}</b> */}
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      window.$("#modalformentor").modal("hide");
                    }}
                    style={{ border: "none", background: "white" }}
                  >
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <section className="content">
                      <div className="row">
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupEmail"
                        >
                          <Form.Label> Session Type : </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={sessionType}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupPassword"
                        >
                          <Form.Label> Session : </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={session}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupEmail"
                        >
                          <Form.Label> User : </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={user}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupPassword"
                        >
                          <Form.Label> Mentor: </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={mentor}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupEmail"
                        >
                          <Form.Label> Session Date Time (User) : </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={sessionTimeUser}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-6 mb-3"
                          controlId="formGroupPassword"
                        >
                          <Form.Label>
                            {" "}
                            Session Date Time (Mentor) :{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={sessionTimeMentor}
                            disabled
                          />
                        </Form.Group>
                      </div>
                    </section>
                  </div>
                </div>
              </>
            ) : (
              <div className="loader"></div>
            )}
          </div>
          <div class="modal-footer d-flex justify-content-between"></div>
        </div>

        {/* <!-- Form END --> */}
      </div>
    </>
  );
};
export default Transactions;
