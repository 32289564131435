import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';
import dotenv from 'dotenv';
dotenv.config();


// const baseUrl =  "http://localhost:3001/v1/";
const baseUrl = "https://learnetapi.acolabz.com/v1/"



const loginByPassword = async (data) => {

    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            }
        }
        // /bookings/reschedule/id,data
        const response = await axios.post(`${baseUrl}admin/auth/login`, data, headers).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.success === false)
                    return toast.error(error.response.data.message)
            }
            else if (error.response.status === 401) {
                return toast.error("Unauthorized access")
            }
            else if (error.response.status === 404) {
                return toast.error("User not found")
            }
            else if (error.response.status === 500) {
                return toast.error("Internal server error")
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }
}



export default loginByPassword

